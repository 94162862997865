import {isWeb} from "../../global";
import {Alert} from "react-native";
import * as DocumentPicker from "expo-document-picker";
import AsyncStorage from "@react-native-async-storage/async-storage";

export async function UploadDoc(title, id, cf) {

    // No permissions request is necessary for launching the image library
    let result = await DocumentPicker.getDocumentAsync({
        //let result = await ImagePicker.launchImageLibraryAsync({
        //mediaTypes: ImagePicker.MediaTypeOptions.All,
        //allowsEditing: true,
        //aspect: [4, 3],
        //quality: 1,
        //allowsMultipleSelection: true
        //type: 'image/*'
    });

    /*if (result.type === 'success') {
        Upload(result.name, result.mimeType, result.uri, title)
    }

    async function Upload(name, type, uri, title) {*/
        let path
        switch (title) {
            case 'Generali':
                path = 'general'
                break
            case 'Corsi':
                path = id
                break
            case 'Personali':
                path = cf
                break
        }
        //console.log((uri.split(':')) )
        let scelta;
        if (result.uri.split(':')[0] === 'data') {
            result.uri = result.uri.split(',')[1]
            //console.log(result.uri)
            scelta = 'web'

            fetch(await AsyncStorage.getItem('dominio') + 'doc/upload.php', {
                method: 'POST',
                /*header: {
                    'content-type': 'multipart/form-data',
                },*/
                body: JSON.stringify({
                    scelta: scelta,
                    name: result.name,//nome + '.' + name.split('.')[1],
                    type: result.mimeType,
                    uri: result.uri,
                    path: path
                })
            }).then((response) => response.json())
                .then((responseJson) => {
                    isWeb ? alert(responseJson) :
                        Alert.alert(responseJson)
                }).catch((error) => {
                console.error(error);
            })
        }
        /*else {
            let formData = new FormData();
            formData.append('image', {
                uri: uri,
                name: nome + '.' + name.split('.')[1],
                type: type
            });

            fetch(dominio + 'eventi/upload.php', {
                method: 'POST',
                header: {
                    'content-type': 'multipart/form-data',
                },
                body: formData
            }).then((response) => response.json())
                .then((responseJson) => {
                    isWeb ? alert(responseJson) :
                        Alert.alert(responseJson)
                    setRefresh(true)
                }).catch((error) => {
                console.error(error);
            })
        }*/
   // }
}

export async function DeleteDoc(title, id, cf, item) {
    let path
    switch (title) {
        case 'Generali':
            path = 'general'
            break
        case 'Corsi':
            path = id
            break
        case 'Personali':
            path = cf
            break
    }
    fetch(await AsyncStorage.getItem('dominio') + 'doc/doc.php', {
        method: 'POST',
        body: JSON.stringify({
            scelta: 'delete',
            path: path,
            item: item
        })
    }).then((response) => response.json())
        .then((responseJson) => {
                isWeb ? alert(responseJson) :
                    Alert.alert(responseJson)
            }
        ).catch((error) => {
        console.error(error);
    })//.finally()
}