import {
    ActivityIndicator,
    Alert,
    FlatList,
    Image,
    ImageBackground,
    Linking,
    ScrollView,
    Text,
    TextInput,
    TouchableOpacity,
    View
} from "react-native";
import * as React from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Constants from "expo-constants";
import Elimina from "../admin/component/elimina";
import {GoBack} from "../common/goBack";
import linking from "react-native-web/dist/exports/Linking";
import Evento from "../admin/component/evento";
import {isWeb} from "../../global";
import * as DocumentPicker from "expo-document-picker";
import Docu from "../admin/component/docu";
import {Picker} from "@react-native-picker/picker";
import {DeleteDoc, UploadDoc} from "../../scripts/admin/doc";
import Salva from "../admin/component/salva";
import Modifica from "../admin/component/modifica";
import Update from "../admin/component/update";

export default function Doc({navigation}) {

    const [loading, setLoading] = React.useState(false)
    const [user, setUser] = React.useState(null);
    const [dominio, setDominio] = React.useState(null);
    const [refresh, setRefresh] = React.useState(true)
    const [cf, setCf] = React.useState('')
    const [id, setId] = React.useState()

    const [docGeneral, setDocGeneral] = React.useState([])
    const [docCorso, setDocCorso] = React.useState([])
    const [docPersonal, setDocPersonal] = React.useState([])


    const [docCorsi, setDocCorsi] = React.useState([])
    const [docPersonali, setDocPersonali] = React.useState([])

    const [corsi, setCorsi] = React.useState([]);
    const [soci, setSoci] = React.useState([]);

    const [modifica, setModifica] = React.useState(null);

    React.useEffect(() => {
        // Fetch the token from storage then navigate to our appropriate place
        const bootstrapAsync = async () => {
            //await ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.LANDSCAPE)

            const user = await AsyncStorage.getItem('login')
            setUser(user)

            const dominio = await AsyncStorage.getItem('dominio')
            setDominio(dominio)

            if (user === 'admin') {
                //lista corsi per admin
                fetch(dominio + 'corsi/corsi.php', {
                    method: 'POST',
                    body: JSON.stringify({
                        scelta: 'list'
                    })
                }).then((response) => response.json())
                    .then((responseJson) => {
                        setCorsi(responseJson);
                        setId(responseJson[0].id)
                        let elenco = []
                        responseJson.forEach(item => {
                            let indice = item.id
                            fetch(dominio + 'doc/doc.php', {
                                method: 'POST',
                                body: JSON.stringify({
                                    scelta: 'readPath',
                                    path: item.id
                                })
                            }).then((response) => response.json())
                                .then((responseJson) => {
                                    //elenco.push({indice: indice, array: responseJson})
                                    setDocCorso(obj => [...obj, {indice, responseJson}])
                                }).catch((error) => {
                                console.error(error);
                            });
                        })
                        //setDocCorso(elenco)

                    }).catch((error) => {
                    console.error(error);
                })

                //lista soci per admin
                fetch(dominio + 'soci.php', {
                    method: 'POST'
                }).then((response) => response.json())
                    .then((responseJson) => {
                        responseJson.sort((a, b) => a.nome.localeCompare(b.nome))
                        setSoci(responseJson);
                        setCf(responseJson[0].cf)
                        let elenco = []
                        responseJson.forEach(item => {
                            let indice = item.cf
                            fetch(dominio + 'doc/doc.php', {
                                method: 'POST',
                                body: JSON.stringify({
                                    scelta: 'readPath',
                                    path: item.cf
                                })
                            }).then((response) => response.json())
                                .then((responseJson) => {
                                    //elenco.push({indice, responseJson})
                                    setDocPersonal(obj => [...obj, {indice, responseJson}])

                                }).catch((error) => {
                                console.error(error);
                            });
                        })

                        //setDocPersonal(elenco)

                    }).catch((error) => {
                    console.error(error);
                })//.finally(() => setLoading(false));
            } else {
                //personali
                const email = await AsyncStorage.getItem('userToken')

                fetch(dominio + 'socio.php', {
                    method: 'POST',
                    body: JSON.stringify({
                        email: email,
                    })
                }).then((response) => response.json())
                    .then((responseJson) => {
                        const codice = responseJson.cf
                        setCf(codice)
                        fetch(dominio + 'socioCorsi.php', {
                            method: 'POST',
                            body: JSON.stringify({
                                cfSocio: codice,
                            })
                        }).then((response) => response.json())
                            .then((responseJson) => {
                                setCorsi(responseJson)
                                setId(responseJson[0].id)
                                let elenco = []
                                responseJson.forEach(item => {
                                    let indice = item.id
                                    fetch(dominio + 'doc/doc.php', {
                                        method: 'POST',
                                        body: JSON.stringify({
                                            scelta: 'readPath',
                                            path: item.id
                                        })
                                    }).then((response) => response.json())
                                        .then((responseJson) => {
                                            //elenco.push({indice, responseJson})
                                            setDocCorso(obj => [...obj, {indice, responseJson}])

                                        }).catch((error) => {
                                        console.error(error);
                                    })
                                })
                                //setDocCorso(elenco)
                            }).catch((error) => {
                            console.error(error);
                        });
                        fetch(dominio + 'doc/doc.php', {
                            method: 'POST',
                            body: JSON.stringify({
                                scelta: 'readPath',
                                path: codice
                            })
                        }).then((response) => response.json())
                            .then((responseJson) => {
                                setDocPersonal(responseJson)
                            }).catch((error) => {
                            console.error(error);
                        })
                    }).catch((error) => {
                    console.error(error);
                });
            }

            //lista generali
            fetch(dominio + 'doc/doc.php', {
                method: 'POST',
                body: JSON.stringify({
                    scelta: 'readPath',
                    path: 'general'
                })
            }).then((response) => response.json())
                .then((responseJson) => {
                    setDocGeneral(responseJson)
                }).catch((error) => {
                console.error(error);
            })

        }
       bootstrapAsync()
        setLoading(false)
      setRefresh(false)
   }, [refresh]);

    function FindElenco(title) {
        if (title === 'Generali') {
            return docGeneral
        } else if (title === 'Corsi') {
            let elencoCorsi
            docCorso.forEach(item => {
                if (item.indice === id) {
                    elencoCorsi = item.responseJson
                }
            })
            return elencoCorsi
        } else if (title === 'Personali' && user === 'admin') {
            let elencoPersonali

            docPersonal.forEach(item => {
                if (item.indice === cf) {
                    elencoPersonali = item.responseJson
                }
            })
            return elencoPersonali
        } else if (title === 'Personali' && user !== 'admin') {
            return docPersonal
        }
    }

    function RenderDoc(props) {
        const [path, setPath] = React.useState(
            props.title === 'Generali' ? 'general' : props.title === 'Corsi' ? id : cf
        )
        let elenco = FindElenco(props.title, id, cf)

        return (
            <View>
                <Text style={{
                    margin: 20,
                    fontSize: 20,
                    fontWeight: 'bold'
                }}>{props.title}</Text>

                {props.title !== 'Generali' && (user === 'admin' || (user !== 'admin' && props.title === 'Corsi')) ?
                    <Picker mode='dropdown'
                            style={{
                                margin: 10,
                            }}
                        /*itemStyle={{
                            textAlign: "center",
                        }}*/
                            selectedValue={
                                props.title === 'Corsi' ?
                                    id
                                    :
                                    props.title === 'Personali' ?
                                        cf
                                        : null
                            }
                            onValueChange={(itemValue, itemIndex) => {
                                props.title === 'Corsi' ?
                                    setId(itemValue)
                                    :
                                    props.title === 'Personali' ?
                                        setCf(itemValue)
                                        : null
                            }}>
                        {
                            props.title === 'Corsi' ?
                                corsi.map(corso => <Picker.Item label={corso.nome} value={corso.id} key={corso.id}/>)
                                :
                                props.title === 'Personali' ?
                                    soci.map(socio => <Picker.Item label={
                                        socio.nome + ' ' +
                                        socio.cognome + ' - ' +
                                        socio.cf
                                    } value={socio.cf} key={socio.cf}/>)
                                    : null
                        }
                    </Picker>
                    : null
                }

                {
                    elenco === null || elenco === undefined ?
                        //props.elenco.length > 0 ?
                        <View style={{
                            //flex: 1,
                            alignItems: 'center',
                            justifyContent: 'center',
                            margin: 20
                        }}>

                            <Image source={require('../../assets/iconPdfX.png')} style={{
                                //resizeMode: 'contain',
                                width: 100,
                                height: 100
                            }}/>
                            <Text>NESSUN DOCUMENTO PRESENTE</Text>

                        </View>

                        :

                        <FlatList data={
                            elenco.sort((a, b) => a.localeCompare(b))
                        }
                                  onRefresh={() => setRefresh(true)}
                                  refreshing={refresh}
                                  horizontal
                                  style={{
                                      margin: 20
                                  }}
                                  showsVerticalScrollIndicator={false}
                                  showsHorizontalScrollIndicator={true}
                                  keyExtractor={(item, index) => index.toString()}
                                  renderItem={({item}) =>
                                      <View style={{
                                          //flexDirection: 'row',
                                          //alignItems: 'center',
                                          //margin: 15
                                      }}>
                                          <TouchableOpacity
                                              onPress={() => {
                                                  Linking.openURL(dominio + 'doc/' + path + '/' + item)

                                                  //setShow(!show)
                                                  //setSelected(item)
                                              }}
                                              style={{
                                                  alignItems: 'center',
                                                  width: 140,
                                                  minHeight: 120,
                                              }}
                                          >
                                              <Image
                                                  style={{
                                                      resizeMode: 'contain',
                                                      width: 100,
                                                      height: 100
                                                  }}
                                                  source={require('../../assets/iconPdf.png')}/>

                                              <Text style={{
                                                  width: 100,
                                                  textAlign: 'center'
                                              }}>
                                                  {item.split('.')[0]}
                                              </Text>
                                          </TouchableOpacity>

                                          {
                                              user === 'admin' ?

                                                  modifica ?
                                                      <View style={{
                                                          alignItems: 'center',
                                                          justifyContent: 'center'
                                                      }}>
                                                          <Elimina
                                                              function={() => {
                                                                  setLoading(true)
                                                                  DeleteDoc(props.title, id, cf, item)
                                                                  // .finally(() => setRefresh(true))
                                                                  setRefresh(true)
                                                              }
                                                              }/>
                                                      </View>
                                                      : null : null}

                                      </View>
                                  }
                        />
                }

                {user === 'admin' && modifica ?
                    <View style={{
                        //flex: 1,
                        alignItems: 'center',
                        justifyContent: 'center',
                        margin: 5
                    }}>
                        <Docu function={() => {
                            setLoading(true)
                            UploadDoc(props.title, id, cf).finally(() => setLoading(false))}
                        }/>
                    </View>
                    : null}

            </View>
        )
    }

   /* if (refresh === true) {
        bootstrapAsync()
        setRefresh(false)
        return (
            <View style={{
                flex: 1,
                backgroundColor: 'white',
                paddingTop: Constants.statusBarHeight,
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <ActivityIndicator/>
            </View>
        )
    } else {*/
        return (
            <View style={{
                flex: 1,
                backgroundColor: 'white',
                paddingTop: Constants.statusBarHeight,
            }}>
                <Update loading={loading} />
                <GoBack function={() => {
                    navigation.goBack()
                }} color='black'/>
                <ScrollView>
                    <View>


                        {user === 'admin' ?
                            modifica ?

                                <View>

                                    <View style={{marginRight: '10%', alignItems: 'flex-end'}}>
                                        <Salva function={() => {
                                            setModifica(!modifica)
                                            setRefresh(true)
                                        }}/>
                                    </View>
                                </View>
                                :
                                <View style={{marginLeft: '10%'}}>

                                    <Modifica function={() => setModifica(!modifica)}/>
                                </View>
                            : null}

                        <RenderDoc title='Generali'/>
                        <RenderDoc title='Corsi'/>
                        <RenderDoc title='Personali'/>
                    </View>
                </ScrollView>
            </View>

        )
   // }
}