import {Alert, TextInput} from "react-native";
import * as React from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";

export function Input(props) {

    const storeData = async (key, value) => {
        try {
            await AsyncStorage.setItem(key, value)
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <TextInput
            placeholder={props.nome}
            multiline
            numberOfLines={4}
            onChangeText={data => {//SetChange(data, props.name)
                switch (props.name) {
                    case 'disciplina':
                        //AsyncStorage.setItem('disciplina', data)
                        storeData('disciplina', data)
                        break
                    case 'lunedi':
                        //await AsyncStorage.setItem('lunedi', data)
                        storeData('lunedi', data)
                        break
                    case 'martedi':
                        //await AsyncStorage.setItem('martedi', data)
                        storeData('martedi', data)
                        break
                    case 'mercoledi':
                        //await AsyncStorage.setItem('mercoledi', data)
                        storeData('mercoledi', data)
                        break
                    case 'giovedi':
                        //await AsyncStorage.setItem('giovedi', data)
                        storeData('giovedi', data)
                        break
                    case 'venerdi':
                        //await AsyncStorage.setItem('venerdi', data)
                        storeData('venerdi', data)
                        break
                    case 'sabato':
                        // await AsyncStorage.setItem('sabato', data)
                        storeData('sabato', data)
                        break
                }
            }}
            underlineColorAndroid='transparent'
            style={{
                textAlign: 'center'
            }}

        />
    )
}
