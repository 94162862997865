import {Alert, StyleSheet, Text, TextInput, TouchableOpacity, View} from "react-native";
import React from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {isWeb} from "../../global";
export default function ForgotPass() {

    async function Pass(){
        const dominio = await AsyncStorage.getItem('dominio')
        fetch(dominio + 'authentication/forgotPassword.php', {
            method: 'POST',

            body: JSON.stringify({
                email: email,
                cf: cf
            })
        }).then((response) => response.json())
            .then((responseJson) => {
                !isWeb ? Alert.alert(responseJson) :
                    alert(responseJson)
            }).catch((error) => {
            console.error(error);
        })//.finally(() => setLoading(false));
    }

    const [email, setEmail] = React.useState(null);
    const [cf, setCF] = React.useState(null);

    return (

        <View style={{
            flex: 1,
            backgroundColor: 'white',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
            <TextInput
                placeholder='CODICE FISCALE'
                onChangeText={data => setCF(data)}
                underlineColorAndroid='transparent'
                style={styles.TextInputStyleClass}
                autoCapitalize='characters'
                maxLength={16}
            />
            <Text style={{
                color: 'red',
                marginBottom: 5
            }}>{cf === '' ? 'campo obbligatorio' : '' ||
            cf ? cf.length !== 16 ? 'il codice fiscale deve essere di 16 cifre' : '' : ''
            }</Text>
            <TextInput
                placeholder="INSERISCI EMAIL"
                onChangeText={data => setEmail(data)}
                underlineColorAndroid='transparent'
                style={styles.TextInputStyleClass}
                autoCapitalize={'none'}
                keyboardType='email-address'
            />
            <Text style={{
                color: 'red',
                marginBottom: 5
            }}>{email === '' ? 'campo obbligatorio' : '' ||
            email ? !email.includes('@' && '.') ? 'formato email non corretto' : '' : ''
            }</Text>

            <TouchableOpacity style={{
                marginTop: 30,
                borderWidth: 1,
                borderRadius: 10,
                alignItems: 'center',
                justifyContent: 'center',
                width: '50%',
                textAlign: "center",
                fontSize: 30,
                fontWeight: "bold",
                marginBottom: 40
            }} onPress={() => Pass()}>

                <Text style={{
                    fontSize: 20,
                    fontWeight: "bold",
                    textAlign: 'center'
                }}>RECUPERA</Text>

            </TouchableOpacity>

        </View>

    )
}

const styles = StyleSheet.create({

    MainContainer: {

        justifyContent: 'center',
        //alignItems: 'center',
        flex: 1,
        margin: 10
    },

    TextInputStyleClass: {
        textAlign: 'center',
        marginBottom: 5,
        height: 40,
        width: '60%',
        borderWidth: 1,
        borderColor: 'black',
        borderRadius: 10,
    },


});