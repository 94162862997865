import {Image, ScrollView, Text, TouchableOpacity, View} from "react-native";
import * as React from "react";
import * as Linking from 'expo-linking';
import {Entypo, FontAwesome5, Ionicons, MaterialCommunityIcons} from "@expo/vector-icons";
import Constants from "expo-constants";
import {GoBack} from "./goBack";

export default function Info({navigation}) {

    function ImageBottom(props) {
        return (
            <View style={{
                width: '25%',
                //margin: 1
            }}>
                <Image source={props.link}
                       style={{
                           resizeMode: 'contain',
                           width: '100%',
                           height: '100%',
                       }}/>
            </View>
        )
    }

    return (
        <View style={{
            flex: 1,
            backgroundColor: 'white',
            paddingTop: Constants.statusBarHeight,
        }}>

            {/* goBack */}
            <GoBack function={() => {
                navigation.goBack()
            }} color='black'/>
            {/* /goBack */}

            {/* header */}
            <ScrollView>
            <View style={{alignItems: 'center'}}>
                <View style={{
                    width: 692 / 100 * 25,
                    height: 800 / 100 * 25
                }}>
                    <Image source={require('../../assets/log.png')}
                           style={{
                               resizeMode: 'contain',
                               width: '100%',
                               height: '100%'
                           }}/>
                </View>
            </View>
            {/* /header */}

            {/* body */}
            <View style={{
                marginTop: '10%',
                alignItems: 'center'
            }}>

                <View style={{
                    flexDirection: 'row',
                    justifyContent: 'center'
                }}>

                    <MaterialCommunityIcons name="facebook" size={40} color='#1877F2' style={{margin: 5}}
                    onPress={() => Linking.openURL('https://www.facebook.com/daniel.s.danceschool.DANIELACRESCENZIO/')}
                    />
                    <MaterialCommunityIcons name="instagram" size={40} color='#F56040' style={{margin: 5}}
                                            onPress={() => Linking.openURL('https://www.instagram.com/danielsdanceschool/')}
                    />
                    <FontAwesome5 name="tiktok" size={36} color="black" style={{margin: 6}}
                                  onPress={() => Linking.openURL('https://www.tiktok.com/@danielsdanceschool')}
                    />
                </View>

                <TouchableOpacity
                    style={{
                        margin: 20}}
                    onPress={()=> Linking.openURL('https://wa.me/393888517783')}
                >
                    <Image source={require('../../assets/w.png')}
                           style={{
                               width: 200,
                               height: 60,
                               resizeMode: 'stretch'
                           }}
                    />
                </TouchableOpacity>

                {/* number */}
                <TouchableOpacity style={{flexDirection: 'row', marginBottom: 20}}
                                  onPress={() => Linking.openURL('tel: +393888517783')}>
                    <Ionicons name="call" size={24} color="black"/>
                    <Text style={{
                        fontSize: 20
                    }}> 388 851 7783</Text>
                </TouchableOpacity>

                {/* /number */}

                {/* email */}
                <TouchableOpacity style={{flexDirection: 'row', marginBottom: 20}}
                                  onPress={() => Linking.openURL('mailto:dds07@pec.it')}>
                    <Entypo name="new-message" size={24} color="black"/>
                    <Text style={{
                        fontSize: 20
                    }}> dds07@pec.it</Text>
                </TouchableOpacity>

                <TouchableOpacity style={{flexDirection: 'row', marginBottom: 40}}
                                  onPress={() => Linking.openURL('mailto:dds16042007@gmail.com')}>
                    <Entypo name="new-message" size={24} color="black"/>
                    <Text style={{
                        fontSize: 20
                    }}> dds16042007@gmail.com</Text>
                </TouchableOpacity>
                {/* /email */}

                <TouchableOpacity
                    onPress={() => Linking.openURL('https://goo.gl/maps/StGjv3995d7MJqSFA')}
                >
                    <View style={{flexDirection: 'row'}}>
                        <MaterialCommunityIcons name="google-maps" size={24} color="black"/>
                        <Text style={{
                            fontSize: 20
                        }}

                        >Via la Sorte, 32 - Grottaglie (TA)</Text>
                    </View>
                </TouchableOpacity>
            </View>
            {/* /body */}

            {/* map */}
            <View  style={{
               alignItems: 'center'
            }}>
            <TouchableOpacity
                onPress={() => Linking.openURL('https://goo.gl/maps/StGjv3995d7MJqSFA')}
                style={{
                    width: '80%',
                    height: 150
                }}>

                <Image source={require('../../assets/map.jpg')}
                       style={{
                           resizeMode: 'contain',
                           width: '100%',
                           height: '100%'
                       }}/>
            </TouchableOpacity>
            </View>
                {/* /map */}

                <View style={{
                    flexDirection: 'row',
                    height: '20%',
                    //justifyContent: 'flex-end',
                    //position: 'absolute',
                    //marginTop: '50%',
                    //alignItems: 'flex-end',
                    //zIndex: -5
                    //opacity: 0.5
                }}>
                    <ImageBottom link={require('../../assets/3.jpg')}/>
                    <ImageBottom link={require('../../assets/1.jpeg')}/>
                    <ImageBottom link={require('../../assets/2.png')}/>
                    <ImageBottom link={require('../../assets/4.jpg')}/>
                </View>

            </ScrollView>
        </View>
    )
}
