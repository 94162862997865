import React from 'react';

import {
    StyleSheet,
    TextInput,
    View,
    Alert,
    TouchableOpacity,
    Text,
    Platform, ScrollView
} from 'react-native';
import AsyncStorage from "@react-native-async-storage/async-storage";
import {Picker} from '@react-native-picker/picker';
import Constants from "expo-constants";
import {Ionicons} from "@expo/vector-icons";

const isWeb = Platform.OS === 'web'

export default function Registration({navigation}) {
    const [isloading, setLoading] = React.useState(true);

    const [nome, setNome] = React.useState(null);
    const [cognome, setCognome] = React.useState(null);
    const [sesso, setSesso] = React.useState('M');
    const [email, setEmail] = React.useState(null);
    const [cellulare, setCellulare] = React.useState(null);
    const [corso1, setCorso1] = React.useState(1);
    const [corso2, setCorso2] = React.useState(1);
    const [corso3, setCorso3] = React.useState(1);
    const [cf, setCF] = React.useState(null);
    const [password, setPassword] = React.useState(null);
    const [password2, setPassword2] = React.useState(null);
    const [contatore, setContatore] = React.useState(1);

    const [corsi, setCorsi] = React.useState([]);

    React.useEffect(() => {
        const bootstrapAsync = async () => {
            const dominio = await AsyncStorage.getItem('dominio')
            fetch(dominio + 'corsi/corsi.php', {
                method: 'POST',
                body: JSON.stringify({
                    scelta: 'list'
                })
            }).then((response) => response.json())
                .then((responseJson) => {
                    setCorsi(responseJson)
                }).catch((error) => {
                console.error(error);

            })
        }
        bootstrapAsync()

        /*return () => {
            const bootstrapAsync = async () => {
                //await ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT_UP);
            }
            bootstrapAsync();
        };*/
    }, []);

    const registrationFunction = async () => {
        console.log(nome, cognome, sesso, email, cellulare, contatore, corso1, corso2, corso3, cf, password)
        const dominio = await AsyncStorage.getItem('dominio')
        fetch(dominio + 'authentication/registration.php', {
            method: 'POST',
            body: JSON.stringify({
                nome: nome,
                cognome: cognome,
                sesso: sesso,
                email: email,
                cellulare: cellulare,
                contatore: contatore,
                corso1: corso1,
                corso2: corso2,
                corso3: corso3,
                cf: cf,
                password: password
            })
        }).then((response) => response.json())
            .then((responseJson) => {
                // Showing response message coming from server after inserting records.
                !isWeb ?
                    Alert.alert(responseJson) :
                    alert(responseJson)
                navigation.goBack();
            }).catch((error) => {
            console.error(error);
        })
    }

    function Controllo() {
        if (!nome || nome === '' || !cognome || cognome === '' || !email || email === '' ||
            !password || password === '' || !password2 || password2 === '' ||
            cf.length !== 16 || cellulare.length !== 10 || !email.includes('@' && '.') ||
            password.length < 8) {
            return (
                !isWeb ?
                    Alert.alert('COMPLETARE TUTTI I CAMPI') :
                    alert('COMPLETARE TUTTI I CAMPI')
            )
        } else {
            if (password === password2) {
                registrationFunction()
            } else {
                !isWeb ?
                    Alert.alert('LE PASSWORD NON CORRISPONDONO') :
                    alert('LE PASSWORD NON CORRISPONDONO')
            }
        }
    }

    function Corsi(props) {
        return (
            <Picker mode='dropdown'
                    style={{
                        //marginLeft: 10,
                    }}
                /*itemStyle={{
                    textAlign: "center",
                }}*/
                    selectedValue={props.corso}
                    onValueChange={(itemValue, itemIndex) => {
                        props.setCorso(itemValue)
                    }}>
                {corsi.map(corso => <Picker.Item label={corso.nome} value={corso.id} key={corso.id}/>)}
            </Picker>
        )
    }

    function Corsi1() {
        return (
            <Corsi corso={corso1} setCorso={setCorso1}/>
        )
    }

    function Corsi2() {
        return (
            <View>
                <Corsi corso={corso1} setCorso={setCorso1}/>
                <Corsi corso={corso2} setCorso={setCorso2}/>
            </View>
        )
    }

    function Corsi3() {
        return (
            <View>
                <Corsi corso={corso1} setCorso={setCorso1}/>
                <Corsi corso={corso2} setCorso={setCorso2}/>
                <Corsi corso={corso3} setCorso={setCorso3}/>
            </View>
        )
    }

    return (
        <ScrollView style={{
            flex: 1,
            backgroundColor: 'white',
            //paddingTop: Constants.statusBarHeight,
            //justifyContent: 'center',
            //alignItems: 'center'
        }}>
            <View style={{
                //flex: 1,
                //backgroundColor: 'white',
                paddingTop: Constants.statusBarHeight,
                //justifyContent: 'center',
                alignItems: 'center'
            }}>
                <Text style={{
                    fontSize: 20, color: "black", textAlign: 'center', marginBottom: 15, //marginTop: 70
                }}>REGISTRAZIONE</Text>

                <TextInput
                    placeholder="INSERISCI NOME"
                    onChangeText={data => setNome(data)}
                    underlineColorAndroid='transparent'
                    style={styles.TextInputStyleClass}
                />
                <Text style={{
                    color: 'red',
                    marginBottom: 5
                }}>{nome === '' ? 'campo obbligatorio' : ''}</Text>
                <TextInput
                    placeholder="INSERISCI COGNOME"
                    onChangeText={data => setCognome(data)}
                    underlineColorAndroid='transparent'
                    style={styles.TextInputStyleClass}
                />
                <Text style={{
                    color: 'red',
                    marginBottom: 5
                }}>{cognome === '' ? 'campo obbligatorio' : ''}</Text>
                <View style={{
                    marginBottom: 5
                }}>
                    <Text style={{
                        //fontSize: 20, color: "#DD2C00", textAlign: 'center', marginBottom: 15
                    }}>INSERISCI SESSO</Text>
                    <Picker mode='dropdown'
                            style={{
                                //marginLeft: 10,
                            }}
                            selectedValue={sesso}
                            onValueChange={(itemValue, itemIndex) => setSesso(itemValue)}>
                        <Picker.Item label="M" value="M"/>
                        <Picker.Item label="F" value="F"/>
                    </Picker>
                </View>

                <TextInput
                    placeholder='CODICE FISCALE'
                    onChangeText={data => setCF(data)}
                    underlineColorAndroid='transparent'
                    style={styles.TextInputStyleClass}
                    autoCapitalize='characters'
                    maxLength={16}
                />
                <Text style={{
                    color: 'red',
                    marginBottom: 5
                }}>{cf === '' ? 'campo obbligatorio' : '' ||
                cf ? cf.length !== 16 ? 'il codice fiscale deve essere di 16 cifre' : '' : ''
                }</Text>
                <TextInput
                    placeholder='INSERISCI CELLULARE'
                    keyboardType='phone-pad'
                    onChangeText={data => setCellulare(data)}
                    underlineColorAndroid='transparent'
                    style={styles.TextInputStyleClass}
                    maxLength={10}
                />
                <Text style={{
                    color: 'red',
                    marginBottom: 5
                }}>{cellulare === '' ? 'campo obbligatorio' : '' ||
                cellulare ? cellulare.length !== 10 ? 'il cellulare deve avere 10 cifre' : '' : ''
                }</Text>
                <TextInput
                    placeholder="INSERISCI EMAIL"
                    onChangeText={data => setEmail(data)}
                    underlineColorAndroid='transparent'
                    style={styles.TextInputStyleClass}
                    autoCapitalize={'none'}
                    keyboardType='email-address'
                />
                <Text style={{
                    color: 'red',
                    marginBottom: 5
                }}>{email === '' ? 'campo obbligatorio' : '' ||
                email ? !email.includes('@' && '.') ? 'formato email non corretto' : '' : ''
                }</Text>
                <TextInput
                    placeholder="INSERISCI PASSWORD"
                    onChangeText={data => setPassword(data)}
                    underlineColorAndroid='transparent'
                    style={styles.TextInputStyleClass}
                    secureTextEntry={true}
                />
                <Text style={{
                    color: 'red',
                    marginBottom: 5
                }}>{password === '' ? 'campo obbligatorio' : '' ||
                password ? password.length < 8 ? 'la password deve essere lunga almeno 8 caratteri' : '' : ''
                }</Text>
                <TextInput
                    placeholder="RIPETI PASSWORD"
                    onChangeText={data => setPassword2(data)}
                    underlineColorAndroid='transparent'
                    style={styles.TextInputStyleClass}
                    secureTextEntry={true}
                />
                <Text style={{
                    color: 'red',
                    marginBottom: 5
                }}>{
                    password2 === '' ? 'campo obbligatorio' : '' ||
                    password2 !== password ? 'le password non corrispondono' : ''}</Text>
                <View style={{
                    //flexDirection: 'row',
                    //alignItems: 'center',
                    marginBottom: 10,
                }}>
                    <View style={{
                        flexDirection: 'row',
                        alignItems: 'center'
                    }}>
                        <Text style={{
                            //fontSize: 20, color: "#DD2C00", textAlign: 'center', marginBottom: 15
                        }}>INSERISCI IL/I CORSO/I FREQUENTATO/I </Text>
                        <Ionicons name="remove" size={30} color="black" onPress={() => setContatore(contatore - 1)}/>
                        <Ionicons name="add" size={30} color="black" onPress={() => setContatore(contatore + 1)}/>
                    </View>
                    {contatore < 1 ? setContatore(1) : <Text></Text>}
                    {contatore === 1 ? <Corsi1/> : <Text></Text>}
                    {contatore === 2 ? <Corsi2/> : <Text></Text>}
                    {contatore === 3 ? <Corsi3/> : <Text></Text>}
                    {contatore > 3 ? setContatore(3) : <Text></Text>}
                </View>


                <TouchableOpacity style={{
                    marginTop: 30,
                    borderWidth: 1,
                    borderRadius: 10,
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '50%',
                    textAlign: "center",
                    fontSize: 30,
                    fontWeight: "bold",
                    marginBottom: 40
                }} onPress={() => Controllo()}>

                    <Text style={{
                        fontSize: 20,
                        fontWeight: "bold",
                        textAlign: 'center'
                    }}>ISCRIVITI</Text>

                </TouchableOpacity>
            </View>
        </ScrollView>
    );
}

const styles = StyleSheet.create({

    MainContainer: {

        justifyContent: 'center',
        //alignItems: 'center',
        flex: 1,
        margin: 10
    },

    TextInputStyleClass: {
        textAlign: 'center',
        marginBottom: 5,
        height: 40,
        width: '60%',
        borderWidth: 1,
        borderColor: 'black',
        borderRadius: 10,
    },


});