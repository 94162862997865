import {Text, TouchableOpacity} from "react-native";
import {AntDesign, Ionicons, MaterialIcons} from "@expo/vector-icons";
import * as React from "react";

export default function Elimina(props){
    return(
        <TouchableOpacity style={{flexDirection: 'row', alignItems: 'center'}}
                          onPress={props.function}
        >
            <MaterialIcons name="delete" size={24} color="red"/>
            <Text style={{
                color: 'black'
            }}>ELIMINA</Text>

        </TouchableOpacity>
    )
}