import {Alert, FlatList, Text, TouchableOpacity, View} from "react-native";
import * as React from 'react';
import Constants from "expo-constants";
import {Ionicons} from "@expo/vector-icons";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {GoBack} from "../common/goBack";

export default function Corsi({navigation}) {
    const [corsi, setCorsi] = React.useState([]);

    React.useEffect(() => {
        const bootstrapAsync = async () => {
            const dominio = await AsyncStorage.getItem('dominio')
            fetch(dominio + 'corsi/corsi.php', {
                method: 'POST',
                /*headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },*/
                body: JSON.stringify({
                    scelta: 'list'
                })
            }).then((response) => response.json())
                .then((responseJson) => {
                    responseJson.pop()
                    setCorsi(responseJson)
                }).catch((error) => {
                console.error(error);
            })

        }
        bootstrapAsync()


        /*return () => {
            const bootstrapAsync = async () => {
                //await ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT_UP);
            }
            bootstrapAsync();
        };*/

    }, []);

    return (
        <View
            style={{
                flex: 1,
                backgroundColor: 'black',
                paddingTop: Constants.statusBarHeight,
                ///backgroundColor: 'white',
                //alignItems: 'center',
                //justifyContent: 'center',
            }}>
            <GoBack function={() => {
                navigation.goBack()
            }} color='white'/>
            <View
                style={{
                    alignItems: 'center',
                    //marginBottom: 20
                    //justifyContent: 'center',
                }}>
                <Text style={{
                    fontSize: 35,
                    color: 'white'
                }}
                >I NOSTRI CORSI:</Text>
            </View>
            <FlatList data={corsi}
                //ItemSeparatorComponent = {() => <View style={{ height: .5, width: "100%", backgroundColor: "#000" }}/>}
                keyExtractor={(item, index) => index.toString()}
                //refreshing={refreshing}
                //onRefresh={onRefresh}
                style={{flex:1}}
                      renderItem={({item}) => (
                          <View
                              style={{
                                  //flex: 1,
                                  alignItems: 'center',
                                  marginTop: '4%',
                                  //marginLeft: '4%',
                                  //justifyContent: 'center'
                              }}
                          >
                              <TouchableOpacity style={{
                                  marginTop: 10,
                                  //marginLeft: '15%',
                                  borderRadius: 5,
                                  shadowColor: 'rgba(0,0,0, .4)', // IOS
                                  shadowOffset: {height: 1, width: 1}, // IOS
                                  shadowOpacity: 1, // IOS
                                  shadowRadius: 1, //IOS
                                  backgroundColor: '#fff',
                                  elevation: 10, // Android
                                  width: 300,
                                  height: 50,
                                  justifyContent: "center",
                              }}
                                                onPress={() => navigation.navigate('Corso', {corso: item})}
                              >
                                  <View style={{
                                      //justifyContent: "center",
                                      alignItems: 'center'
                                  }}>
                                      <Text>{item.nome}</Text>
                                  </View>
                              </TouchableOpacity>
                          </View>
                      )}/>
            <Text style={{marginBottom: 10}}> </Text>
        </View>
    );
}
