import AsyncStorage from '@react-native-async-storage/async-storage';
import {Platform, StyleSheet} from "react-native";

export const iconSize = 26;
export const iconColor = "white";
export const isWeb = Platform.OS === 'web'

//export let dominio = AsyncStorage.getItem('dominio')

export const styles = StyleSheet.create({
//Menu
    iconView: {
        alignItems: 'center',
        //borderWidth: 1,
        //borderColor: 'white',
        margin: 30
    },
    iconText: {
        color: iconColor,
        fontWeight: 'bold'
    },
    icon: {
width: 50,
        height: 50
    },
});
