import {
    ActivityIndicator,
    Alert,
    Button, Dimensions,
    FlatList,
    Image, Linking,
    Modal,
    Platform, RefreshControl, SafeAreaView,
    ScrollView,
    Text,
    TextInput,
    TouchableOpacity,
    View
} from "react-native";
import * as React from "react";
import Constants from 'expo-constants';
import AsyncStorage from "@react-native-async-storage/async-storage";
import {Ionicons, MaterialIcons} from "@expo/vector-icons";
import {isWeb} from "../../global";
//import * as ImagePicker from "expo-image-picker";
import * as DocumentPicker from 'expo-document-picker';
import * as ImagePicker from 'expo-image-picker';
import {GoBack} from "./goBack";
//import DocumentPicker from 'react-native-document-picker';
import {Video, AVPlaybackStatus} from 'expo-av';
import * as Device from "expo-device";
import Evento from "../admin/component/evento";
import Elemento from "../admin/component/elemento";
import Update from "../admin/component/update";

export default function AlbumPage({navigation, route}) {

    const [media, setMedia] = React.useState([]);
    const [lenght, setLenght] = React.useState();
    const [i, setI] = React.useState(0);

    const [selected, setSelected] = React.useState('');
    const [user, setUser] = React.useState(null);
    const [dominio, setDominio] = React.useState(null);
    const [refresh, setRefresh] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [show, setShow] = React.useState(false);
    const [caricato, setCaricato] = React.useState(false);

    const video = React.useRef(null);
    const [status, setStatus] = React.useState({});

    const larg = (Dimensions.get('window').width - 16) / 3

    const data = route.params

    React.useEffect(() => {
        // Fetch the token from storage then navigate to our appropriate place

        const bootstrapAsync = async () => {
            //await ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.LANDSCAPE)

            const user = await AsyncStorage.getItem('login')
            setUser(user)

            const dominio = await AsyncStorage.getItem('dominio')
            setDominio(dominio)
            fetch(dominio + 'gscripts/gallery.php', {
                method: 'POST',
                body: JSON.stringify({
                    scelta: 'readCartella',
                    nome: data.nome
                })
            }).then((response) => response.json())
                .then((responseJson) => {
                        setMedia(responseJson)
                        //console.log(responseJson)
                        /*if (responseJson) {
                            setSelected(responseJson[0].url)
                        }*/
                    }
                ).catch((error) => {
                console.error(error);
            })
        }
        bootstrapAsync()

        setRefresh(false)

        /*return () => {
            const bootstrapAsync = async () => {
                //await ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT_UP);
            }
            bootstrapAsync();
        };*/

    }, [refresh]);

    function dataURItoBlob(dataURI) {
        // convert base64/URLEncoded data component to raw binary data held in a string
        let byteString;

        if (dataURI.split(',')[0].indexOf('base64') >= 0)
            byteString = atob(dataURI.split(',')[1]);
        else
            byteString = unescape(dataURI.split(',')[1]);

        // separate out the mime component
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        // write the bytes of the string to a typed array
        const ia = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ia], {type: mimeString});
    }

    /*function DownloadAlbum(){
        for (let item of media){
        Linking.openURL(dominio + 'gscripts/download.php' + '?f=' +
            'gallery/' + data.nome + '/' + item.url)
    }}*/

    const pickImage = async () => {
        //let result = await ImagePicker.launchImageLibraryAsync({
        let result = await DocumentPicker.getDocumentAsync({
            //mediaTypes: ImagePicker.MediaTypeOptions.All,
            //aspect: [4, 3],
            //quality: 1,
            //allowsMultipleSelection: true,
            //base64: true,
            //exif: true
            multiple: true,
            type: ['image/*', 'video/*']
        });

        //console.log(result);
        /*const blob = dataURItoBlob(result.selected[0].uri);
        console.log(blob)
        const objectURL = URL.createObjectURL(blob);
        console.log(objectURL)*/

        if (result.type === 'success') {
            //console.log(JSON.parse(JSON.stringify(result.output[0])))
            // console.log(JSON.stringify(result.output[0]))
            // console.log(readAsDataURL(result.output[0])))
            // const blob = dataURItoBlob(result.uri);
            //console.log(blob)
            setLoading(true)

            let scelta;
            let uri;
            if (result.uri.split(':')[0] === 'data') {
                let lenght =result.output.length
                setLenght(lenght)
                setI(0)
                let i = 0
                for (let item of result.output) {
                    const reader = new FileReader();
                    reader.readAsDataURL(item);
                    reader.onload = function () {
                        uri = reader.result

                        uri = uri.split(',')[1]
                        //console.log(uri)
                        scelta = 'web'

                        fetch(dominio + 'gscripts/upload.php', {
                            method: 'POST',
                            /*header: {
                                'content-type': 'multipart/form-data',
                            },*/
                            body: JSON.stringify({
                                scelta: scelta,
                                path: data.nome,
                                name: item.name,// + '.' + result.name.split('.')[1],
                                type: item.type,
                                tipo: item.type.split('/')[0],
                                uri: uri
                            })
                        }).then((response) => response.json())
                            .then((responseJson) => {
                                i += 1
                                setI(i)
                                if (i === lenght) {
                                    setLoading(false)
                                    isWeb ? alert(responseJson) :
                                        Alert.alert(responseJson)
                                    setRefresh(true)
                                }
                            }).catch((error) => {
                            console.error(error);
                        })
                    }

                }
            } else {
                let formData = new FormData();
                formData.append('image', {
                    uri: result.uri,
                    name: result.name + '.' + result.name.split('.')[1],
                    type: type
                });

                fetch(dominio + 'gscripts/upload.php', {
                    method: 'POST',
                    header: {
                        'content-type': 'multipart/form-data',
                    },
                    body: formData
                }).then((response) => response.json())
                    .then((responseJson) => {
                        isWeb ? alert(responseJson) :
                            Alert.alert(responseJson)
                        setRefresh(true)
                    }).catch((error) => {
                    console.error(error);
                })

            }
        }

    }

    function UploadAlbum() {

    }

    function DeleteF(nome) {
        fetch(dominio + 'gscripts/gallery.php', {
            method: 'POST',
            body: JSON.stringify({
                scelta: 'deleteImage',
                nome: data.nome,
                file: nome

            })
        }).then((response) => response.json())
            .then((responseJson) => {
                    isWeb ? alert(responseJson) :
                        Alert.alert(responseJson)
                setShow(!show)
                    setRefresh(!refresh)
                }
            ).catch((error) => {
            console.error(error);
        })//.finally()
    }

    function DeleteC(nome) {
        fetch(dominio + 'gscripts/gallery.php', {
            method: 'POST',
            body: JSON.stringify({
                scelta: 'deleteCartella',
                nome: nome
            })
        }).then((response) => response.json())
            .then((responseJson) => {
                    isWeb ? alert(responseJson) :
                        Alert.alert(responseJson)
                    setRefresh(!refresh)
                }
            ).catch((error) => {
            console.error(error);
        })//.finally()
    }

    return (
        <View style={{
            flex: 1,
            backgroundColor: 'white',
            paddingTop: Constants.statusBarHeight,
        }}>
            <GoBack function={() => {
                navigation.goBack()
            }} color='black' function2={() => navigation.navigate('Home')}/>

            <View style={{
                alignItems: 'center'
            }}>
                {user === 'admin' ?
                    <View>
                        <Elemento function={() => pickImage()
                        }/>
                    </View>
                    : null}
            </View>

            <Update loading={loading} other={
                lenght > 1 ?
                <Text>{i+1}/{lenght}</Text> :
                null
            }/>

            <Modal
                animationType="fade"
                visible={show}
                onRequestClose={() => {
                    setShow(!show)
                    setCaricato(false)
                }}>

                <GoBack color='black' function={() => {
                    setShow(!show)
                    setCaricato(false)
                }} function2={() => {
                    navigation.navigate('Home')
                    setShow(!show)
                    setCaricato(false)
                }}/>

                <View style={{
                    flex: 1,
                    margin: 10,
                }}>
                    <View style={{
                        alignItems: 'flex-end', marginRight: '2%'
                    }}>

                        {user === 'admin' ?
                            <TouchableOpacity style={{flexDirection: 'row', margin: 2}}
                                              onPress={() => DeleteF(selected)}
                            >
                                <MaterialIcons name="delete" size={24} color="red"/>
                                <Text style={{
                                    color: 'black'
                                }}>ELIMINA</Text>
                            </TouchableOpacity>

                            : <TouchableOpacity
                                style={{
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    margin: 2
                                }}
                                onPress={() => Linking.openURL(dominio + 'gscripts/download.php' + '?f=' +
                                    //dominio +
                                    'gallery/' + data.nome + '/' + selected
                                )}>
                                <MaterialIcons name="file-download" size={24} color="grey"/>
                                <Text style={{
                                    color: 'black'
                                }}>SCARICA</Text>
                            </TouchableOpacity>

                        }
                    </View>
                    {selected.split('.')[1] === 'jpg' ||
                    selected.split('.')[1] === 'png' ||
                    selected.split('.')[1] === 'jpeg' ||
                    selected.split('.')[1] === 'JPG' ||
                    selected.split('.')[1] === 'PNG' ||
                    selected.split('.')[1] === 'JPEG' ?

                        <View style={{
                            width: '100%',
                            height: '100%',
                        }}>
                            <Image
                                style={{
                                    resizeMode: 'contain',
                                    width: '100%',
                                    height: '100%',
                                    position: 'absolute',
                                    //display: caricato ? 'none' : 'flex'
                                    //visibility: caricato ? 'hidden' : 'inherit'
                                    //zIndex: caricato ? -1 : 1
                                    //opacity: caricato ? 0 : 1
                                }}
                                source={{
                                    uri: dominio + 'galleryTemp/' + data.nome + '/' + selected
                                    //.split('.')[0] + '.' + selected.split('.')[1].toLowerCase(),
                                }}/>

                            <Image
                                style={{
                                    resizeMode: 'contain',
                                    width: '100%',
                                    height: '100%',
                                    //display: caricato ? 'flex' : 'none',
                                    //visibility: caricato ? 'inherit' : 'hidden'
                                    //position: 'absolute',
                                    //zIndex: caricato ? 1 : -1
                                    opacity: caricato ? 1 : 0
                                }}
                                onLoad={() => setCaricato(true)}
                                source={{
                                    uri: dominio + 'gallery/' + data.nome + '/' + selected,
                                }}/>

                        </View>

                        :

                        <View style={{}}>

                            <Text style={{textAlign: 'center', fontSize: 10, marginBottom: 5}}>
                                Se il video è troppo lungo o non viene visualizzato correttamente si consiglia
                                di scaricarlo</Text>
                            {isWeb && Device.osName !== 'iOS' ?
                                <Text style={{textAlign: 'center', fontSize: 50, margin: 30}}>Video troppo pesante,
                                    scaricarlo</Text>
                                :

                                <Video
                                    ref={video}
                                    style={{
                                        alignSelf: 'center',
                                        //aspectRatio: 1,
                                        width: '100%',
                                        height: 200,
                                    }}
                                    source={{
                                        uri: selected ?
                                            dominio + 'gallery/' + data.nome + '/' + selected
                                            : null
                                    }}
                                    useNativeControls
                                    resizeMode="contain"
                                    //isLooping
                                    //onPlaybackStatusUpdate={status => setStatus(() => status)}
                                />}
                        </View>}


                </View>
            </Modal>

            {!media ?

                <ScrollView
                    refreshControl={
                        <RefreshControl
                            refreshing={refresh}
                            onRefresh={() => setRefresh(true)}
                        />
                    }
                >
                    <View style={{
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>

                        <View style={{
                            //flex: 1,
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <Image source={require('../../assets/iconPhotoX.png')} style={{
                                //resizeMode: 'contain',
                                width: 100,
                                height: 100
                            }}/>
                            <Text>NESSUN MEDIA PRESENTE</Text>
                        </View>
                    </View>
                </ScrollView>

                :
                <View style={{
                    flex: 1,
                }}>

                    <View style={{alignItems: 'flex-end', marginRight: '3%', margin: 10}}>

                        {user === 'admin' ?
                            <TouchableOpacity style={{flexDirection: 'row', alignItems: 'center'}}
                                              //onPress={() => UploadAlbum()}
                            >{/*
                                <MaterialIcons name="file-upload" size={24} color="black"/>
                                <Text style={{
                                    color: 'black'
                                }}> CARICA ALBUM</Text>
                                */}
                            </TouchableOpacity>

                            :

                            <TouchableOpacity
                                style={{flexDirection: 'row', alignItems: 'center', margin: 2}}
                                onPress={() => {
                                    isWeb ? alert('Verra scaricato un archivio zip contenente tutte le foto, scaricare i video singolarmente poichè molto pesanti') :
                                        Alert.alert('Verra scaricato un archivio zip contenente tutte le foto, scaricare i video singolarmente poichè molto pesanti')
                                    Linking.openURL(dominio + 'gscripts/download.php' + '?f=' +
                                        'galleryDownload/' + data.nome + '.zip')
                                }}
                                //onPress={() => DownloadAlbum()}
                            >
                                <MaterialIcons name="file-download" size={24} color="grey"/>
                                <Text style={{
                                    color: 'black'
                                }}>SCARICA ALBUM</Text>
                            </TouchableOpacity>


                        }
                    </View>

                    <FlatList data={media.sort((a, b) => a.url.localeCompare(b.url))}
                        // .sort((a, b) => a.url.split('.')[1] - b.url.split('.')[1])}
                              numColumns={3}
                              style={{margin: 2}}
                              onRefresh={() => setRefresh(true)}
                              refreshing={refresh}
                        //horizontal
                        // maxToRenderPerBatch={1}
                        //initialNumToRender={1}
                        //updateCellsBatchingPeriod={1}
                              showsVerticalScrollIndicator={false}
                              keyExtractor={(item, index) => index.toString()}
                              renderItem={({item, index}) =>
                                  <View style={{
                                      //alignItems: 'center',
                                      margin: 2
                                  }}>

                                      <TouchableOpacity
                                          onPress={() => {
                                              setSelected(item.url)
                                              setShow(true)
                                              //const timer =
                                              //setTimeout(() => setCaricato(true), 1000);
                                              //clearTimeout(timer);
                                          }}
                                      >
                                          {item.url.split('.')[1] === 'jpg' ||
                                          item.url.split('.')[1] === 'png' ||
                                          item.url.split('.')[1] === 'jpeg' ||
                                          item.url.split('.')[1] === 'JPG' ||
                                          item.url.split('.')[1] === 'PNG' ||
                                          item.url.split('.')[1] === 'JPEG' ?

                                              <Image
                                                  style={{
                                                      resizeMode: 'stretch',
                                                      width: larg,
                                                      height: larg
                                                  }}
                                                  source={{
                                                      uri: dominio + 'galleryTemp/' + data.nome + '/' + item.url

                                                      //item.url.split('.')[0] + '.' + item.url.split('.')[1].toLowerCase()
                                                  }}/>

                                              :
                                              <View style={{
                                                  width: larg,
                                                  height: larg,
                                                  alignItems: 'center',
                                              }}>
                                                  <Text
                                                      style={{textAlign: 'center'}}>{item.url.split('.')[0]}</Text>
                                                  <Image
                                                      style={{
                                                          resizeMode: 'contain',
                                                          width: larg - 30,
                                                          height: larg - 30
                                                      }}
                                                      source={require('../../assets/iconVideo.png')}/>
                                              </View>
                                          }

                                      </TouchableOpacity>

                                  </View>
                              }/>

                </View>
            }
        </View>
    )
}