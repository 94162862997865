import {
    ActivityIndicator,
    Alert,
    Button,
    FlatList,
    Image, Linking,
    Modal,
    Platform, Pressable, RefreshControl, SafeAreaView,
    ScrollView, StyleSheet,
    Text,
    TextInput,
    TouchableOpacity,
    View
} from "react-native";
import * as React from "react";
import Constants from 'expo-constants';
import AsyncStorage from "@react-native-async-storage/async-storage";

import * as ImagePicker from 'expo-image-picker';
import {isWeb} from "../../global";
import {Menu, Provider} from "react-native-paper";
import {AntDesign, FontAwesome, Ionicons, MaterialIcons} from "@expo/vector-icons";
import Evento from "../admin/component/evento";
import Salva from "../admin/component/salva";
import Modifica from "../admin/component/modifica";
import {GoBack} from "./goBack";
import Elimina from "../admin/component/elimina";
import Conferma from "../admin/component/conferma";


export default function Gallery({navigation}) {
    const [c, setC] = React.useState(null);
    const [conferma, setConferma] = React.useState(false);
    const [images, setImages] = React.useState(null);
    const [dominio, setDominio] = React.useState(null);
    const [cartelle, setCartelle] = React.useState([]);
    const [refresh, setRefresh] = React.useState(false)
    const [user, setUser] = React.useState(null);
    const [modifica, setModifica] = React.useState(null);

    React.useEffect(() => {
        // Fetch the token from storage then navigate to our appropriate place
        const bootstrapAsync = async () => {

            const user = await AsyncStorage.getItem('login')
            setUser(user)

            const dominio = await AsyncStorage.getItem('dominio')
            setDominio(dominio)

            fetch(dominio + 'gscripts/gallery.php', {
                method: 'POST',
                body: JSON.stringify({
                    scelta: 'readCartelle'
                })
            }).then((response) => response.json())
                .then((responseJson) =>
                    setCartelle(responseJson)
                ).catch((error) => {
                console.error(error);
            })
        }
        bootstrapAsync()
        setRefresh(false)
    }, [refresh]);

    const styles = StyleSheet.create({
        container: {
            padding: 50,
            flexDirection: 'row',
            justifyContent: 'center',
            height: 200,
        },
    });

    function Create(nome) {
        fetch(dominio + 'gscripts/gallery.php', {
            method: 'POST',
            body: JSON.stringify({
                scelta: 'createCartella',
                nome: nome
            })
        }).then((response) => response.json())
            .then((responseJson) => {
                    isWeb ? alert(responseJson) :
                        Alert.alert(responseJson)
                    setRefresh(!refresh)
                }
            ).catch((error) => {
            console.error(error);
        })//.finally(setRefresh(!refresh))
    }

    function DeleteC(nome) {

        fetch(dominio + 'gscripts/gallery.php', {
            method: 'POST',
            body: JSON.stringify({
                scelta: 'deleteCartella',
                nome: nome,
            })
        }).then((response) => response.json())
            .then((responseJson) => {
                    isWeb ? alert(responseJson) :
                        Alert.alert(responseJson)
                    setRefresh(!refresh)
                }
            ).catch((error) => {
            console.error(error);
        })//.finally()
    }

    async function Upload() {
        for (const item of images) {
            console.log(item)
            let formData = new FormData();
            formData.append('image', {
                uri: item.uri,
                name: `image.jpg`,
                type: `image/jpg`
            });
            const dominio = await AsyncStorage.getItem('dominio')
            fetch(dominio + 'gallery/upload.php', {
                method: 'POST',
                header: {
                    'content-type': 'multipart/form-data',
                },
                body: formData
            }).then((response) => response.json())
                .then((responseJson) =>
                    console.log(responseJson)
                ).catch((error) => {
                console.error(error);
            })
        }
    }

    function Vuoto() {
        return (
            <ScrollView
                refreshControl={
                    <RefreshControl
                        refreshing={refresh}
                        onRefresh={() => setRefresh(true)}
                    />
                }
            >
                <View style={{
                    //flex: 1,
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>

                    {/*isWeb ?

                        <TouchableOpacity
                            style={{
                                margin: 20
                            }}
                            onPress={() => setRefresh(true)}
                        >
                            <Ionicons name="refresh" size={26} color='black'/>
                        </TouchableOpacity>

                        : null*/}

                    <Image source={require('../../assets/iconAlbumX.png')} style={{
                        //resizeMode: 'contain',
                        width: 100,
                        height: 100
                    }}/>
                    <Text>NESSUN ALBUM PRESENTE</Text>
                </View>
            </ScrollView>
        )
    }

    function New() {
        const [nome, setNome] = React.useState(null);

        function Create(nome) {
            fetch(dominio + 'gscripts/gallery.php', {
                method: 'POST',
                body: JSON.stringify({
                    scelta: 'createCartella',
                    nome: nome
                })
            }).then((response) => response.json())
                .then((responseJson) => {
                        isWeb ? alert(responseJson) :
                            Alert.alert(responseJson)
                        setRefresh(!refresh)
                    }
                ).catch((error) => {
                console.error(error);
            })//.finally(setRefresh(!refresh))
        }

        return (
            <View style={{
                flexDirection: 'row',
                alignItems: 'center',
                //margin: 10,
                //marginLeft: '15%'
            }}>
                <FontAwesome name="plus" size={30} color="green" style={{//margin: 10
                }}/>
                <TextInput
                    placeholder="INSERISCI NOME ALBUM"
                    onChangeText={data => setNome(data)}
                    underlineColorAndroid='transparent'
                    style={{
                        borderWidth: 1,
                        margin: 10,
                        padding: 10,
                        justifyContent: 'center'
                    }}
                />
                <TouchableOpacity style={{
                    backgroundColor: 'green',
                    borderRadius: 10
                }}
                                  onPress={() => Create(nome)}
                >
                    <Text

                        style={{
                            margin: 4,
                        }}>CREA ALBUM</Text>
                </TouchableOpacity>
            </View>
        )
    }

    function Album() {
        return (
            <View style={{flex: 1, alignItems: 'center'}}>

                {/*isWeb ?

                    <TouchableOpacity
                        style={{
                            margin: 20
                        }}
                        onPress={() => setRefresh(true)}
                    >
                        <Ionicons name="refresh" size={26} color='black'/>
                    </TouchableOpacity>

                    : null*/}

                {user === 'admin' ?
                    modifica ?

                        <View>
                            <View style={{alignItems: 'center'}}>
                                <New/>
                            </View>
                            <View style={{alignItems: 'flex-end'}}>
                                <Salva function={() => setModifica(!modifica)}/>
                            </View>
                        </View>

                        :
                        <Modifica function={() => setModifica(!modifica)}/>

                    : null}

                <FlatList data={cartelle.sort((a, b) => a.localeCompare(b))}
                          onRefresh={() => setRefresh(true)}
                          refreshing={refresh}
                          showsVerticalScrollIndicator={false}
                          showsHorizontalScrollIndicator={false}
                          keyExtractor={(item, index) => index.toString()}
                          renderItem={({item}) =>
                              <View style={{
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                              }}>
                                  <TouchableOpacity
                                      onPress={() => navigation.navigate('AlbumPage', {nome: item})}
                                      //onPress={() => Album()}
                                      style={{
                                          //width: 100,
                                          //height: 100,
                                          alignItems: 'center',
                                          margin: 20
                                      }}
                                  >
                                      <Image source={require('../../assets/iconAlbum.png')} style={{
                                          //resizeMode: 'contain',
                                          width: 100,
                                          height: 100
                                      }}/>
                                      <Text>{item}</Text>
                                  </TouchableOpacity>

                                  {user === 'admin' ?

                                      modifica ?
                                              <Elimina function={() => {
                                                  setC(item)
                                                  setConferma(true)
                                              }}/>
                                          : null : null}

                              </View>
                          }/>
            </View>
        )
    }

    return (
        <View style={{
            flex: 1,
            backgroundColor: 'white',
            paddingTop: Constants.statusBarHeight,
        }}>

            <GoBack function={() => {
                navigation.goBack()
            }} color='black'/>

            <Conferma loading={conferma}
                      funcSi={() => {
                          DeleteC(c)
                          setConferma(false)
                      }}
            funcNo={()=> setConferma(false)}
            />

            {user === 'socio' && !cartelle ?

                <Vuoto/>

                :

                null

            }
            {user === 'admin' && !cartelle ?

                <View style={{alignItems: 'center'}}>
                    <New/>

                    <Vuoto/>
                </View>

                :

                null
            }
            {cartelle ?

                <Album/>

                :

                null
            }
        </View>

    )
}