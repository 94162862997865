import 'react-native-gesture-handler';
//library
import * as React from 'react';
import {
    Text,
    TextInput,
    View,
    Image,
    TouchableOpacity, Platform, Alert, Dimensions, ScrollView, ImageBackground, FlatList,
} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {NavigationContainer} from '@react-navigation/native';
import {createStackNavigator} from "@react-navigation/stack";

import {createNativeStackNavigator} from "@react-navigation/native-stack";
import {createDrawerNavigator, DrawerContentScrollView, DrawerItemList, DrawerItem} from "@react-navigation/drawer";
import {createBottomTabNavigator} from '@react-navigation/bottom-tabs';
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from 'react-native-responsive-screen';
import AwesomeAlert from "react-native-awesome-alerts";
import {createMaterialBottomTabNavigator} from '@react-navigation/material-bottom-tabs';

import * as ScreenOrientation from "expo-screen-orientation";

//variables
import {Ionicons, MaterialCommunityIcons} from "@expo/vector-icons";
//screens
import Registration from './screens/common/registration';
import AdminHome from "./screens/admin/adminHome";
import Corsi from "./screens/socio/corsi";
import Pay from "./screens/socio/pay";
import Notifiche from "./screens/socio/notifiche";
import Doc from "./screens/socio/doc";
import Info from "./screens/common/info";
import Home from "./screens/socio/home";
import Corso from "./screens/socio/corso"
import Constants from "expo-constants";
import ForgotPass from "./screens/socio/forgotPass";
import RecoverPass from "./screens/socio/recoverPass";
import Orario from "./screens/common/orario";
import AdminCorsi from "./screens/admin/adminCorsi";
import Gallery from "./screens/common/gallery";
import AlbumPage from "./screens/common/albumPage";
import Eventi from "./screens/common/eventi";
import {GoBack} from "./screens/common/goBack";

const isWeb = Platform.OS === 'web'
const AuthContext = React.createContext();

const Stack = createNativeStackNavigator();
const AuthStackScreen = ({navigation}) => (
    <Stack.Navigator>
        <Stack.Screen name="Login" component={Login} options={{
            headerShown: false
        }}/>
        <Stack.Screen name="Registration" component={Registration} options={{
            headerShown: true
        }}/>
        <Stack.Screen name="Info" component={Info} options={{
            headerShown: false
        }}/>
        <Stack.Screen name="ForgotPass" component={ForgotPass} options={{
            headerShown: true
        }}/>
    </Stack.Navigator>
);

const Tab = createMaterialBottomTabNavigator();

function SocioTab() {
    return (
        <Tab.Navigator
            activeColor="white"
            inactiveColor="gray"
            barStyle={{backgroundColor: 'black'}}
        >
            <Tab.Screen name="Home" component={HomeStack}
                        options={{
                            tabBarLabel: 'Home',
                            tabBarIcon: ({color}) => (
                                <MaterialCommunityIcons name="home" color={color} size={26}/>
                            ),
                        }}
            />
            <Tab.Screen name="Eventi" component={Eventi}
                        options={{
                            tabBarLabel: 'Eventi',
                            tabBarIcon: ({color}) => (
                                <MaterialCommunityIcons name="calendar" color={color} size={26}/>
                            ),
                        }}/>
            <Tab.Screen name="Orario" component={Orario}
                        options={{
                            tabBarLabel: 'Orario',
                            tabBarIcon: ({color}) => (
                                <MaterialCommunityIcons name="clock" color={color} size={26}/>
                            ),
                        }}/>
            <Tab.Screen name="GalleryStack" component={GalleryStack}
                        options={{
                            tabBarLabel: 'Gallery',
                            tabBarIcon: ({color}) => (
                                <MaterialCommunityIcons name="image" color={color} size={26}/>
                            ),
                        }}/>
        </Tab.Navigator>
    );
}

function AdminTab() {
    return (
        <Tab.Navigator
            activeColor="white"
            inactiveColor="gray"
            barStyle={{backgroundColor: 'black'}}
        >
            <Tab.Screen name="Home" component={AdminHome}
                        options={{
                            tabBarLabel: 'Home',
                            tabBarIcon: ({color}) => (
                                <MaterialCommunityIcons name="home" color={color} size={26}/>
                            ),
                        }}
            />
            <Tab.Screen name="Eventi" component={Eventi}
                        options={{
                            tabBarLabel: 'Eventi',
                            tabBarIcon: ({color}) => (
                                <MaterialCommunityIcons name="calendar" color={color} size={26}/>
                            ),
                        }}/>
            <Tab.Screen name="Orario" component={Orario}
                        options={{
                            tabBarLabel: 'Orario',
                            tabBarIcon: ({color}) => (
                                <MaterialCommunityIcons name="clock" color={color} size={26}/>
                            ),
                        }}/>
            <Tab.Screen name="GalleryStack" component={GalleryStack}
                        options={{
                            tabBarLabel: 'Gallery',
                            tabBarIcon: ({color}) => (
                                <MaterialCommunityIcons name="image" color={color} size={26}/>
                            ),
                        }}/>
            <Tab.Screen name="Documenti" component={Doc}
                        options={{
                            tabBarLabel: 'Documenti',
                            tabBarIcon: ({color}) => (
                                <MaterialCommunityIcons name="file" color={color} size={26}/>
                            ),
                        }}/>
            {/*<Tab.Screen name="Corsi" component={AdminCorsi}
                        options={{
                            tabBarLabel: 'Corsi',
                            tabBarIcon: ({color}) => (
                                <MaterialCommunityIcons name="dance-ballroom" color={color} size={26}/>
                            ),
                        }}/>*/}

        </Tab.Navigator>

    );
}

const HomeStack = ({navigation}) => (
    <Stack.Navigator>
        <Stack.Screen name="Home" component={Home} options={{
            headerShown: false
        }}/>
        <Stack.Screen name="Orario" component={Orario} options={{
            headerShown: false
        }}/>
        <Stack.Screen name="Eventi" component={Eventi} options={{
            headerShown: false
        }}/>
        <Stack.Screen name="Gallery" component={Gallery} options={{
            headerShown: false
        }}/>
        <Stack.Screen name="AlbumPage" component={AlbumPage} options={{
            headerShown: false
        }}/>
        <Stack.Screen name="Corsi" component={Corsi} options={{
            headerShown: false,
        }}/>

        <Stack.Screen name="Documenti" component={Doc} options={{
            headerShown: false
        }}/>
        <Stack.Screen name="Pagamenti" component={Pay} options={{
            headerShown: false
        }}/>
        <Stack.Screen name="Profilo" component={Profilo} options={{
            headerShown: false,
        }}/>
        <Stack.Screen name="Info" component={Info} options={{
            headerShown: false
        }}/>
        <Stack.Screen name="Corso" component={Corso} options={{
            headerShown: false,
        }}/>
        <Stack.Screen name="RecoverPass" component={RecoverPass} options={{
            headerShown: false,
        }}/>
    </Stack.Navigator>
);

const SocioStack = ({navigation}) => (
    <Stack.Navigator>
        <Stack.Screen name="Home" component={Home} options={{
            headerShown: false
        }}/>
        <Stack.Screen name="Corsi" component={Corsi} options={{
            headerShown: false,
            /*title: ' ',
            headerStyle: {
                backgroundColor: 'black',
            },
            headerTintColor: 'white',*/
        }}/>
        <Stack.Screen name="Notifiche" component={Notifiche} options={{
            //headerShown: false
        }}/>
        <Stack.Screen name="Documenti" component={Doc} options={{
            //headerShown: false
        }}/>
        <Stack.Screen name="Pagamenti" component={Pay} options={{
            headerShown: false
        }}/>
        <Stack.Screen name="Profilo" component={Profilo} options={{
            headerShown: false,
            /* title: ' ',
             headerStyle: {
                 backgroundColor: 'black',
             },
             headerTintColor: 'white',*/
        }}/>
        <Stack.Screen name="Info" component={Info} options={{
            headerShown: false
        }}/>
        <Stack.Screen name="Corso" component={Corso} options={{
            headerShown: false,
            /*title: ' ',
            headerStyle: {
                backgroundColor: 'black',
            },
            headerTintColor: 'white',*/
        }}/>
        <Stack.Screen name="RecoverPass" component={RecoverPass} options={{
            headerShown: false,
            /*title: ' ',
            headerStyle: {
                backgroundColor: 'black',
            },
            headerTintColor: 'white',*/
        }}/>
    </Stack.Navigator>
);

const AdminStack = ({navigation}) => (
    <Stack.Navigator>
        <Stack.Screen name="Stack" component={AdminTab} options={{
            headerShown: false
        }}/>
        <Stack.Screen name="Home" component={AdminHome} options={{
            headerShown: false
        }}/>
        <Stack.Screen name="Orario" component={Orario} options={{
            headerShown: false
        }}/>
        <Stack.Screen name="AdminCorsi" component={AdminCorsi} options={{
            headerShown: false,
        }}/>
        <Stack.Screen name="GalleryUpload" component={Gallery} options={{
            headerShown: false
        }}/>
        <Stack.Screen name="AlbumPage" component={AlbumPage} options={{
            headerShown: false
        }}/>
    </Stack.Navigator>
);

const GalleryStack = () => (
    <Stack.Navigator>
        <Stack.Screen name="Gallery" component={Gallery} options={{
            headerShown: false
        }}/>
        <Stack.Screen name="AlbumPage" component={AlbumPage} options={{
            headerShown: false
        }}/>
    </Stack.Navigator>
)

export function AdminLogout() {
    const {signOut} = React.useContext(AuthContext);

    return (
        <View style={{
            //flex: 1,
            //backgroundColor: 'black',
            //alignItems: 'center',
            //justifyContent: 'center'
        }}>
            <TouchableOpacity onPress={() => signOut()}
                              style={{
                                  //margin: 10,
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                              }}>
                <Text style={{
                    fontSize: 16,
                }}>LOGOUT </Text>
                <MaterialCommunityIcons name="logout" size={24} color="black"
                    //style={{marginLeft: '85%'}}
                />
            </TouchableOpacity>
        </View>
    )
}

function Profilo({navigation}) {
    const {signOut} = React.useContext(AuthContext);
    const [data, setData] = React.useState([])
    const [corsi, setCorsi] = React.useState([])

    React.useEffect(() => {
        const Boot = async () => {
            const dominio = await AsyncStorage.getItem('dominio')
            const email = await AsyncStorage.getItem('userToken')

            fetch(dominio + 'socio.php', {
                method: 'POST',
                body: JSON.stringify({
                    email: email,
                })
            }).then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson === null) {
                        signOut()
                    } else {
                        setData(responseJson)
                        const codice = responseJson.cf
                        fetch(dominio + 'socioCorsi.php', {
                            method: 'POST',
                            body: JSON.stringify({
                                cfSocio: codice,
                            })
                        }).then((response) => response.json())
                            .then((responseJson) => {
                                setCorsi(responseJson)
                            }).catch((error) => {
                            console.error(error);
                        });
                    }
                }).catch((error) => {
                console.error(error);
            });

        }
        Boot()
    }, []);

    function Lista() {
        return (
            <View style={{
                flex: 0.5
            }}>
                <FlatList data={corsi}
                    //ItemSeparatorComponent = {() => <View style={{ height: .5, width: "100%", backgroundColor: "#000" }}/>}
                          keyExtractor={(item, index) => index.toString()}
                    //refreshing={refreshing}
                    //onRefresh={onRefresh}
                          renderItem={({item}) => (
                              <View style={{
                                  borderColor: 'white',
                                  borderRadius: 5,
                                  borderWidth: 1,
                                  //marginBottom: 5
                              }}>
                                  <Text style={{color: 'white', fontSize: 20}}>{item.nome}</Text>
                              </View>
                          )}/>
            </View>
        )
    }

    function Dato(props) {
        return (
            <View style={{
                borderColor: 'white',
                borderRadius: 5,
                borderWidth: 1,
                marginBottom: 5
            }}>
                <Text style={{color: 'white', fontSize: 20}}>{props.title}: {props.text}</Text>
            </View>
        )
    }

    return (
        <View style={{
            flex: 1,
            paddingTop: Constants.statusBarHeight,
            backgroundColor: 'black',
            //alignItems: 'center',
            //justifyContent: 'center'
        }}>
            <GoBack function={() => {
                navigation.goBack()
            }} color='white'/>
            <View>
                <View style={{
                    alignItems: 'center',
                    //justifyContent: 'center'
                }}>
                    <Ionicons name="person" size={30} color='white' style={{
                        //marginTop: '10%'
                    }}/>
                    <View style={{
                        marginTop: '10%'
                    }}>
                        <Dato title='Nome' text={data.nome}/>
                        <Dato title='Cognome' text={data.cognome}/>
                        <Dato title='Genere' text={data.sesso}/>
                        <Dato title='Email' text={data.email}/>
                        <Dato title='Cellulare' text={data.cellulare}/>
                        <Dato title='CF' text={data.cf}/>
                        <Text style={{color: 'white', fontSize: 20}}>Corsi frequentati: </Text>
                        <Lista/>
                    </View>

                </View>
                <View style={{margin: 20}}>
                    <View>
                        <TouchableOpacity style={{
                            //marginTop: '2%',
                            //marginLeft: '2%'
                        }}
                                          onPress={() => navigation.navigate('RecoverPass', {email: data.email})}
                        >
                            <Text style={{color: 'white', fontSize: 20}}>CAMBIA PASSWORD </Text>
                        </TouchableOpacity>

                    </View>

                    <View>
                        <TouchableOpacity style={{
                            flexDirection: 'row',
                            marginTop: 10,
                            //marginLeft: '55%'
                        }}
                                          onPress={() => signOut()}
                        >
                            <Text style={{color: 'white', fontSize: 20}}>LOGOUT </Text>
                            <MaterialCommunityIcons name="logout" size={24} color="white"
                                                    style={{
                                                        //position: 'absolute',
                                                        //marginLeft: Dimensions.get('window').width /100 * 75,
                                                        //marginTop: Dimensions.get('window').height /100 * 55
                                                    }}/>
                        </TouchableOpacity>
                    </View></View>
            </View>
        </View>
    )
}

function SplashScreen() {
    return (
        <View
            style={{
                flex: 1,
                flexDirection: 'column',
                backgroundColor: 'white',
                alignItems: 'center',
                justifyContent: 'space-around',
            }}>
            <Image source={require('./assets/logo.jpg')}
                   style={{
                       //flex:1,
                       //aspectRatio: 2,
                       //resizeMode: 'contain'
                       width: '50%',
                       height: '10%'
                   }}/>
            <Text>Loading...</Text>
            <Image
                style={{width: 40, height: 50}}
                source={require('./assets/danza.gif')}/>
        </View>
    );
}

function Login({navigation}) {
    const [email, setEmail] = React.useState(null);
    const [password, setPassword] = React.useState(null);
    const {signIn} = React.useContext(AuthContext);

    const storeData = async (value, value2) => {
        try {
            await AsyncStorage.setItem('userToken', value)
            await AsyncStorage.setItem('login', value2)
        } catch (e) {
            console.log(e);
        }
    }

    function Controllo() {
        if (!email || !password || email === '' || password === '') {
            return (
                !isWeb ?
                    Alert.alert('COMPLETARE TUTTI I CAMPI') :
                    alert('COMPLETARE TUTTI I CAMPI')
            )
        } else {
            LoginFunction()
        }
    }

    const LoginFunction = async () => {
        const dominio = await AsyncStorage.getItem('dominio')

        fetch(dominio + 'authentication/login.php', {
            method: 'POST',
            body: JSON.stringify({
                email: email,
                password: password,
            })
        }).then((response) => response.json())
            .then((responseJson) => {
                // If server response message same as Data Matched
                if (responseJson === 0) {
                    isWeb ?
                        alert('Email o Password non validi') :
                        Alert.alert('Email o Password non validi')
                }
                if (responseJson === 2) {
                    isWeb ?
                        alert('ACCOUNT NON ATTIVO') :
                        Alert.alert('ACCOUNT NON ATTIVO')
                    //navigation.replace("DrawerLoginStackScreen");
                    //CodePush.restartApp();
                    //this.props.navigation.navigate('Second', { email: user_email });
                }
                if (responseJson !== 0 && responseJson !== 2) {
                    storeData(responseJson.id, responseJson.type)
                    signIn();
                }
            }).catch((error) => {
            console.error(error);
        });
    }

    function ImageBottom(props) {
        return (
            <View style={{
                width: '25%',
                //margin: 1
            }}>
                <Image source={props.link}
                       style={{
                           resizeMode: 'contain',
                           width: '100%',
                           height: '100%',
                       }}/>
            </View>
        )
    }

    return (
        <View style={{
            flex: 1,
            backgroundColor: 'white',
            //paddingTop: Constants.statusBarHeight,
        }}>

            <ImageBackground source={require('./assets/sfond.jpg')}
                             resizeMode="stretch"
                             style={{
                                 flex: 1,
                                 //justifyContent: "center",
                                 //alignItems: 'center',
                                 //aspectRatio:1/2,
                                 //width: wp('100%')
                                 //height:'80%',
                             }}
                             imageStyle={{
                                 opacity: 0.5,
                             }}
            >
                <ScrollView>
                    {/* icon */}
                    <View style={{marginTop: '7%', marginLeft: '90%'}}>
                        <Ionicons name="ios-information-circle" size={30} color="black"
                                  onPress={() => navigation.navigate('Info')}
                        />
                    </View>
                    {/* /icon */}

                    {/* header */}
                    <View style={{alignItems: 'center', height: '20%', margin: 20}}>

                        {/* logo */}
                        <View style={{
                            width: '60%',//2502 / 100 * 12,
                            height: '100%',//1023 / 100 * 12,
                        }}>
                            <Image source={require('./assets/logo-PhotoRoom.png')}
                                   style={{
                                       resizeMode: 'contain',
                                       width: '100%',
                                       height: '100%',
                                       opacity: 0
                                   }}/>
                        </View>
                        {/* /logo */}

                    </View>
                    {/* /header */}


                    {/* body */}
                    <View style={{
                        alignItems: 'center',
                        height: '50%',
                        margin: '5%'
                    }}>

                        {/* logo */}
                        <View style={{
                            width: '60%',//2502 / 100 * 12,
                            height: '40%'//1023 / 100 * 12,
                        }}>
                            <Image source={require('./assets/logo-PhotoRoom.png')}
                                   style={{
                                       resizeMode: 'contain',
                                       width: '100%',
                                       height: '100%'
                                   }}/>
                        </View>
                        {/* /logo */}


                        {/* credenziali */}
                        <View style={{
                            marginBottom: 5,
                        }}>
                            <TextInput
                                placeholder="Email"
                                placeholderTextColor="black"
                                onChangeText={data => setEmail(data)}
                                underlineColorAndroid='transparent'
                                style={{
                                    borderWidth: 1,
                                    borderRadius: 5,
                                    borderColor: 'grey',
                                    width: 200,
                                    backgroundColor: 'rgba(52, 52, 52, 0.2)',
                                    textAlign: 'center'
                                }}
                            />
                            <Text style={{
                                color: 'red',
                            }}>{email === '' ? 'email obbligatoria' : ''}</Text>
                        </View>

                        <View style={{
                            marginBottom: 5,
                        }}>
                            <TextInput
                                placeholder="Password"
                                placeholderTextColor="black"
                                onChangeText={data => setPassword(data)}
                                underlineColorAndroid='transparent'
                                style={{
                                    borderWidth: 1,
                                    borderRadius: 5,
                                    borderColor: 'grey',
                                    width: 200,
                                    backgroundColor: 'rgba(52, 52, 52, 0.2)',
                                    textAlign: 'center',
                                    //opacity: 1
                                    //zIndex: 2
                                }}
                                secureTextEntry={true}
                            />
                            <Text style={{
                                color: 'red'
                            }}>{password === '' ? 'password obbligatoria' : ''}</Text>
                        </View>
                        {/* /credenziali */}

                        {/* pulsanti */}
                        <View style={{
                            marginTop: '2%',
                            alignItems: 'center'
                        }}>

                            <TouchableOpacity
                                style={{
                                    borderWidth: 1,
                                    borderRadius: 10,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: 200,
                                    marginBottom: 10
                                }}
                                onPress={() => Controllo()}
                            >
                                <Text style={{
                                    fontSize: 30,
                                    fontWeight: "bold"
                                }}>LOGIN</Text>
                            </TouchableOpacity>
                            <TouchableOpacity
                                style={{
                                    borderWidth: 1,
                                    borderRadius: 10,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: 200,
                                    textAlign: "center",
                                    fontSize: 30,
                                    fontWeight: "bold"
                                }}
                                onPress={() => navigation.push("Registration")}
                            >
                                <Text style={{
                                    fontSize: 30,
                                    fontWeight: "bold"
                                }}>REGISTRATI</Text>
                            </TouchableOpacity>
                        </View>

                        <TouchableOpacity
                            onPress={() => navigation.navigate('ForgotPass')}>
                            <Text>Password dimenticata?</Text>
                        </TouchableOpacity>
                        {/* /pulsanti */}

                        {/* image */}
                        {/*<View style={{
                    margin: '3%',
                    width: '75%',//749 / 100 * 30,
                    height: '60%' //894 / 100 * 30,
                }}>
                    <Image source={require('./assets/sf.png')}
                           style={{
                               resizeMode: 'contain',
                               width: '100%',
                               height: '100%'
                           }}/>
                </View>*/}
                        {/* /image */}

                    </View>
                    {/* /body */}

                    {/* bottom */}


                    {/* /bottom */}
                </ScrollView>
            </ImageBackground>
        </View>

    )

}


export default function App() {
    const [state, dispatch] = React.useReducer(
        (prevState, action) => {
            switch (action.type) {
                case 'RESTORE_TOKEN':
                    return {
                        ...prevState,
                        userToken: action.token,
                        userModo: action.modo,
                        isLoading: false,
                    };
                case 'SIGN_IN':
                    return {
                        ...prevState,
                        isSignout: false,
                        userToken: action.token,
                        userModo: action.modo,
                    };
                case 'SIGN_OUT':
                    return {
                        ...prevState,
                        isSignout: true,
                        userToken: null,
                        userModo: null,
                    };
            }
        },
        {
            isLoading: true,
            isSignout: false,
            userToken: null,
            userModo: null,
        }
    );


    React.useEffect(() => {
        // Fetch the token from storage then navigate to our appropriate place

        const bootstrapAsync = async () => {
            let userToken;
            let value;
            try {
                value = await AsyncStorage.getItem('userToken');
                if (value !== null) {
                    userToken = value;
                } else {
                    userToken = null;
                }
            } catch (e) {
                console.log(e);
            }
            let login;
            let value2;
            try {
                value2 = await AsyncStorage.getItem('login');
                if (value2 !== null) {
                    login = value2;
                } else {
                    login = null;
                }
            } catch (e) {
                console.log(e);
            }
            await AsyncStorage.setItem('dominio', 'https://www.dds07.it/scripts/'
            )
            //await AsyncStorage.removeItem('userToken');
            //await AsyncStorage.removeItem('login');

            // After restoring token, we may need to validate it in production apps

            // This will switch to the App screen or Auth screen and this loading
            // screen will be unmounted and thrown away.
            dispatch({type: 'RESTORE_TOKEN', token: userToken, modo: login});
        };

        /*const Run = async () => {
             const storeDominio = async (value) => {
                 try {
                     await AsyncStorage.setItem('dominio', value)
                 } catch (e) {
                     console.log(e);
                 }
             }

             fetch('https://cors-anywhere.herokuapp.com/https://pastebin.com/api/api_raw.php', {
                 //mode: "no-cors",
                 method: "POST",
                 headers: {
                     //"Access-Control-Allow-Origin": "*",
                     //"Access-Control-Allow-Headers": "X-Requested-With",
                     "Content-Type": "application/x-www-form-urlencoded"
                 },

                 body: 'api_option=show_paste&api_user_key=' + '2777af627e9f26d1d93cdaebca5859f0' + '&api_dev_key=' + 'P0Nf_mMA5iYlS-fzBJVuLuc6tw1pGb18' + '&api_paste_key=' + 'ESXfNwPA'
                 //body: 'api_dev_key='+'P0Nf_mMA5iYlS-fzBJVuLuc6tw1pGb18'+'&api_user_name='+'andrewsociety'+'&api_user_password='+'Andrea3316184903'
             }).then((response) => response.text())
                 .then((responseJson) => {
                     // If server response message same as Data Matched
                     console.log(responseJson)
                     storeDominio(responseJson)
                 }).catch((error) => {
                 console.error(error);
                 !isWeb ?
                     Alert.alert('CONNESSIONE INTERNET NON DISPONIBILE') :
                     alert('CONNESSIONE INTERNET NON DISPONIBILE')

             });
         }
        Run();*/
        bootstrapAsync();
    }, []);

    const authContext = React.useMemo(
        () => ({
            signIn: async data => {
                // In a production app, we need to send some data (usually username, password) to server and get a token
                // We will also need to handle errors if sign in failed
                // After getting token, we need to persist the token using `AsyncStorage`
                // In the example, we'll use a dummy token
                let value = await AsyncStorage.getItem('userToken')
                let value2 = await AsyncStorage.getItem('login')
                /*const value = getData();
                const value2 = getLogin();*/
                dispatch({type: 'SIGN_IN', token: value, modo: value2});
            },
            signOut: async data => {
                try {
                    await AsyncStorage.removeItem('userToken');
                    await AsyncStorage.removeItem('login');
                    await ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT_UP)
                } catch (err) {
                }
                dispatch({type: 'SIGN_OUT'})
            },
            /*signUp: async data => {
                // In a production app, we need to send user data to server and get a token
                // We will also need to handle errors if sign up failed
                // After getting token, we need to persist the token using `AsyncStorage`
                // In the example, we'll use a dummy token

                dispatch({ type: 'SIGN_IN', token: 0 });
            },*/
        }),
        []
    );

    return (
        <AuthContext.Provider value={authContext}>
            <NavigationContainer
            >
                {state.isLoading ? (
                    // We haven't finished checking for the token yet
                    <SplashScreen/>
                ) : state.userToken == null ? (
                    // No token found, user isn't signed in
                    <AuthStackScreen
                        /* options={{
                             // When logging out, a pop animation feels intuitive
                             animationTypeForReplace: state.isSignout ? 'pop' : 'push',
                         }}*/
                    />
                ) : state.userModo === 'socio' ? (

                    <HomeStack/>
                ) : (
                    <AdminTab/>

                    /*<SocioStack/>
                ) : (
                    <AdminStack/>*/

                )}
            </NavigationContainer>
        </AuthContext.Provider>
    );
}
