import {
    Alert,
    FlatList,
    Modal,
    Platform,
    ScrollView,
    Text,
    TouchableOpacity,
    View
} from "react-native";
import * as React from "react";
import Constants from 'expo-constants';
import {AntDesign, FontAwesome, Ionicons, MaterialIcons} from "@expo/vector-icons";
import AsyncStorage from "@react-native-async-storage/async-storage";
import * as ImagePicker from 'expo-image-picker';
import Navbar from "../admin/navbar";
import TextAncestorContext from "react-native-web/dist/exports/Text/TextAncestorContext";
import {GoBack} from "./goBack";
import {Input} from "./input";
import {isWeb} from "../../global";
import Modifica from "../admin/component/modifica";
import Salva from "../admin/component/salva";
import Elimina from "../admin/component/elimina";


export default function Orario({navigation}) {

    const [dominio, setDominio] = React.useState(null);
    const [user, setUser] = React.useState(null);

    const isWeb = Platform.OS === 'web'
    const [orario, setOrario] = React.useState([]);
    const [modifica, setModifica] = React.useState(null);
    const [add, setAdd] = React.useState(false);
    const [refresh, setRefresh] = React.useState(false)

    const [disciplina, setDisciplina] = React.useState(null);
    const [lunedi, setLunedi] = React.useState(null);
    const [martedi, setMartedi] = React.useState(null);
    const [mercoledi, setMercoledi] = React.useState(null);
    const [giovedi, setGiovedi] = React.useState(null);
    const [venerdi, setVenerdi] = React.useState(null);
    const [sabato, setSabato] = React.useState(null);

    const larg = '4%'
    const larg2 = '8%'
    const larg3 = '12%'
    const larg4 = '12%'

    const borderWidth = 2
    const fontSize = 15
    const colorName = '#99ccff'


    /*function SetChange(nome) {
        switch (nome) {
            case 'disciplina':
                return setDisciplina
                break
            case 'lunedi':
                setLunedi
                break
            case 'martedi':
                setMartedi
                break
            case 'mercoledi':
                setMercoledi
                break
            case 'giovedi':
                setGiovedi
                break
            case 'venerdi':
                setVenerdi
                break
            case 'sabato':
                setSabato
                break
        }
    }*/

    async function Add() {
        const disciplina = await AsyncStorage.getItem('disciplina')
        const lunedi = await AsyncStorage.getItem('lunedi')
        const martedi = await AsyncStorage.getItem('martedi')
        const mercoledi = await AsyncStorage.getItem('mercoledi')
        const giovedi = await AsyncStorage.getItem('giovedi')
        const venerdi = await AsyncStorage.getItem('venerdi')
        const sabato = await AsyncStorage.getItem('sabato')

        fetch(dominio + 'administration/orario.php', {
            method: 'POST',
            body: JSON.stringify({
                scelta: 'save',
                disciplina: disciplina,
                lunedi: lunedi,
                martedi: martedi,
                mercoledi: mercoledi,
                giovedi: giovedi,
                venerdi: venerdi,
                sabato: sabato
            })
        }).then((response) => response.json())
            .then((responseJson) => {
                    !isWeb ?
                        Alert.alert(responseJson) :
                        alert(responseJson)
                    setRefresh(!refresh)
                    setAdd(!add)
                }
            ).catch((error) => {
            console.error(error);
        })
    }

    function Delete(id) {
        fetch(dominio + 'administration/orario.php', {
            method: 'POST',
            body: JSON.stringify({
                scelta: 'delete',
                id: id
            })
        }).then((response) => response.json())
            .then((responseJson) => {
                    !isWeb ?
                        Alert.alert(responseJson) :
                        alert(responseJson)
                    setRefresh(!refresh)
                }
            ).catch((error) => {
            console.error(error);
        })
    }

    React.useEffect(() => {

        const bootstrapAsync = async () => {

            await AsyncStorage.removeItem('disciplina')
            await AsyncStorage.removeItem('lunedi')
            await AsyncStorage.removeItem('martedi')
            await AsyncStorage.removeItem('mercoledi')
            await AsyncStorage.removeItem('giovedi')
            await AsyncStorage.removeItem('venerdi')
            await AsyncStorage.removeItem('sabato')

            const user = await AsyncStorage.getItem('login')
            setUser(user)

            const dominio = await AsyncStorage.getItem('dominio')
            setDominio(dominio)

            fetch(dominio + 'administration/orario.php', {
                method: 'POST',
                body: JSON.stringify({
                    scelta: 'read'
                })
            }).then((response) => response.json())
                .then((responseJson) =>
                    setOrario(responseJson)
                ).catch((error) => {
                console.error(error);
            })
        }
        bootstrapAsync()

    }, [refresh]);


    function Cella(props) {
        return (
            <View style={{
                alignItems: 'center',
                justifyContent: 'center',
                height: 60,
                width: !isWeb ? larg : larg2,
                minWidth: 120,
                backgroundColor: props.color,
                borderLeftWidth: borderWidth,
                borderTopWidth: !props.sec ? borderWidth : 0,
                borderRightWidth: props.borderRight,
                borderBottomWidth: borderWidth,
            }}>
                {props.input ?
                    <Input nome={props.nome} value={props.value} name={props.name}/>
                    :
                    <Text style={{
                        fontSize: fontSize,
                        textAlign: 'center'
                    }}>{props.nome}
                    </Text>}
            </View>
        )
    }

    return (
        <View
            style={{
                flex: 1,
                backgroundColor: 'white',
                paddingTop: Constants.statusBarHeight,
                //alignItems: 'center'
            }}>

            <GoBack function={() => {
                navigation.goBack()
            }} color='black'/>

            <Modal
                animationType="slide"
                visible={add}
                onRequestClose={() => {
                    setAdd(!add);
                }}>
                <GoBack function={() => setAdd(!add)} color='black'
                        function2={() => {
                            navigation.navigate('Home')
                            setAdd(!add)
                        }}/>

                <View style={{
                    //flex: 1,
                    //alignItems: 'center'
                    //justifyContent: 'center',
                }}>

                    <View style={{}}>
                        <ScrollView horizontal
                            //showsHorizontalScrollIndicator={false}
                        >

                            <View style={{
                                //flex: 1,
                                margin: 10
                            }}>


                                <View style={{
                                    flexDirection: 'row',
                                    // justifyContent: 'center',
                                }}>
                                    <Cella nome='NOME' color={colorName}/>
                                    <Cella nome='LUNEDì' color={colorName}/>
                                    <Cella nome='MARTEDì' color={colorName}/>
                                    <Cella nome='MERCOLEDì' color={colorName}/>
                                    <Cella nome='GIOVEDì' color={colorName}/>
                                    <Cella nome='VENERDì' color={colorName}/>
                                    <Cella nome='SABATO' color={colorName} borderRight={borderWidth}/>
                                </View>

                                <View style={{
                                    flexDirection: 'row',
                                    //justifyContent: 'center',
                                }}>
                                    <Cella nome={'INSERISCI\nDISCIPLINA'} name='disciplina' input={true} sec={true}/>
                                    <Cella nome={'INSERISCI\nORARIO LUNEDI'} name='lunedi' input={true} sec={true}/>
                                    <Cella nome={'INSERISCI\nORARIO MARTEDI'} name='martedi' input={true} sec={true}/>
                                    <Cella nome={'INSERISCI\nORARIO MERCOLEDI'} name='mercoledi' input={true}
                                           sec={true}/>
                                    <Cella nome={'INSERISCI\nORARIO GIOVEDI'} name='giovedi' input={true} sec={true}/>
                                    <Cella nome={'INSERISCI\nORARIO VENERDI'} name='venerdi' input={true} sec={true}/>
                                    <Cella nome={'INSERISCI\nORARIO SABATO'} name='sabato' borderRight={borderWidth}
                                           input={true}
                                           sec={true}/>
                                </View>

                            </View>
                        </ScrollView>
                    </View>
                    <View style={{alignItems: 'flex-end', marginRight: '15%'}}>
                        <Salva function={() => {
                            Add()
                            setAdd(!add)
                        }}/>
                    </View>


                </View>
            </Modal>

            <ScrollView horizontal
                //showsHorizontalScrollIndicator={true}
            >

                <View style={{
                    flex: 1,
                    margin: 10
                }}>

                    {user === 'admin' ?
                        modifica ?
                            <View style={{flexDirection: 'row', justifyContent: 'space-around', margin: 10}}>

                                <TouchableOpacity style={{flexDirection: 'row', alignItems: 'center'}}
                                                  onPress={() => setAdd(!add)}
                                >
                                    <FontAwesome name="plus" size={30} color="black"/>
                                    <Text> AGGIUNGI RIGA</Text>
                                </TouchableOpacity>

                                <Salva function={() => setModifica(!modifica)}/>

                            </View>

                            :

                            <Modifica function={() => setModifica(!modifica)}/>

                        : null
                    }

                    <View style={{
                        flexDirection: 'row'
                    }}>
                        <Cella nome=''/>
                        <Cella nome='LUNEDì' color={colorName}/>
                        <Cella nome='MARTEDì' color={colorName}/>
                        <Cella nome='MERCOLEDì' color={colorName}/>
                        <Cella nome='GIOVEDì' color={colorName}/>
                        <Cella nome='VENERDì' color={colorName}/>
                        <Cella nome='SABATO' color={colorName} borderRight={borderWidth}/>
                        {user === 'admin' ?
                            modifica ?
                                <TouchableOpacity style={{flexDirection: 'row', alignItems: 'center'}}>
                                    <MaterialIcons name="delete" size={24} color="white"/>
                                    <Text style={{
                                        color: 'white'
                                    }}>ELIMINA</Text>
                                </TouchableOpacity>
                                : null
                            : null
                        }
                    </View>

                    <FlatList data={orario}
                        //ItemSeparatorComponent = {{ height: 0.5, width: "100%", backgroundColor: "#000" }}
                              showsVerticalScrollIndicator={false}
                              showsHorizontalScrollIndicator={false}
                              keyExtractor={(item, index) => index.toString()}
                              renderItem={({item, index}) =>
                                  <View style={{
                                      flexDirection: 'row'
                                  }}>
                                      <Cella nome={item.disciplina} stile={!isWeb ? larg : larg2} sec={true}
                                             color={colorName}/>
                                      <Cella nome={item.lunedi} stile={!isWeb ? larg : larg2} sec={true}/>
                                      <Cella nome={item.martedi} stile={!isWeb ? larg : larg2} sec={true}/>
                                      <Cella nome={item.mercoledi} stile={!isWeb ? larg : larg2} sec={true}/>
                                      <Cella nome={item.giovedi} stile={!isWeb ? larg : larg2} sec={true}/>
                                      <Cella nome={item.venerdi} stile={!isWeb ? larg : larg2} sec={true}/>
                                      <Cella nome={item.sabato} stile={!isWeb ? larg : larg2} sec={true}
                                             borderRight={borderWidth}/>
                                      {user === 'admin' ?
                                          modifica ?

                                              <Elimina function={() => Delete(item.id)}/>

                                              : null
                                          : null}
                                  </View>
                              }/>

                </View>
            </ScrollView>
        </View>
    )
}