import {Text, TouchableOpacity} from "react-native";
import {AntDesign, Ionicons} from "@expo/vector-icons";
import * as React from "react";

export default function Salva(props){
    return(
        <TouchableOpacity style={{flexDirection: 'row', alignItems: 'center', margin: 10}}
                          onPress={props.function}
        >
            <AntDesign name="checkcircleo" size={30} color="black"/>
            <Text> SALVA</Text>
        </TouchableOpacity>
    )
}