import {FlatList, Image, ScrollView, Text, TouchableOpacity, View} from "react-native";
import * as React from 'react';
import AsyncStorage from "@react-native-async-storage/async-storage";
import Constants from "expo-constants";
import {Ionicons} from "@expo/vector-icons";
import {GoBack} from "../common/goBack";

export default function Corso({route, navigation}) {

    const {corso} = route.params
    const [images, setImages] = React.useState([]);
    const [dominio, setDominio] = React.useState(null);

    React.useEffect(() => {
        // Fetch the token from storage then navigate to our appropriate place
        const bootstrapAsync = async () => {
            const dominio = await AsyncStorage.getItem('dominio')
            setDominio(dominio)
            fetch(dominio + 'corsi/corsi.php', {
                method: 'POST',
                /*headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },*/
                body: JSON.stringify({
                    scelta: 'readCartella',
                    id: corso.id
                })
            }).then((response) => response.json())
                .then((responseJson) => {
                    setImages(responseJson)
                }).catch((error) => {
                console.error(error);
            })
        }
        bootstrapAsync();

    }, []);

    return (
        <View style={{
            flex: 1,
            backgroundColor: 'black',
            paddingTop: Constants.statusBarHeight,
        }}>
            <GoBack function={() => {
                navigation.goBack()
            }} color='white' function2={()=> navigation.navigate('Home')}/>
            <ScrollView>
                <View style={{
                    alignItems: 'center',
                    marginBottom: 15
                }}>
                    <Text style={{
                        fontSize: 30,
                        fontWeight: 'bold',
                        color: 'white'
                    }}>
                        {corso.nome}
                    </Text>
                </View>
                <View style={{
                    //flex: 1,
                    alignItems: 'center',
                    margin: 10,
                    marginBottom: 20
                    //width: '50%',
                }}>

                    <Image source={{uri: dominio + 'corsi/' + corso.id + '/' + '1.jpg'}} style={{
                        resizeMode: 'contain',
                        width: 300,
                        height: 200,
                        borderRadius: 5
                    }}/>


                    {/*<FlatList data={images}
                    //ItemSeparatorComponent = {() => <View style={{ height: .5, width: "100%", backgroundColor: "#000" }}/>}
                    //keyExtractor={(item, index) => index.toString()}
                    //refreshing={refreshing}
                    //onRefresh={onRefresh}
                    horizontal
                          renderItem={({item}) => (
                              <View
                                  style={{
                                      //flex: 1,
                                  }}>

                                  <Image source={{uri: dominio + 'corsi/' + corso.id + '/' + item}} style={{
                                      resizeMode: 'contain',
                                      width: 300,
                                      height: 200,
                                      borderRadius: 5
                                  }}/>

                              </View>
                          )}/>*/}
                </View>
                <View style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: 15
                    //width: '50%',
                }}>
                    <Text style={{
                        fontSize: 25,
                        color: 'white',
                        textAlign: 'center'
                    }}>
                        {corso.descrizione}
                    </Text>
                </View>
            </ScrollView>
        </View>
    )
}
