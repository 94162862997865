import {Ionicons, MaterialCommunityIcons} from "@expo/vector-icons";
import * as React from "react";
import {SafeAreaView, TouchableOpacity, View} from "react-native";
import {isWeb} from "../../global";

export const GoBack = (props) => (
    <SafeAreaView style={{
        flexDirection: 'row', justifyContent: 'space-between', marginTop: '2%'
    }}>
        <TouchableOpacity
            style={{
                marginLeft: '2%',
            }}
            onPress={props.function}
        >
            <Ionicons name="arrow-back" size={30} color={props.color}/>
        </TouchableOpacity>

        {props.function2 ?
        <TouchableOpacity
            style={{
                marginRight: '2%',
            }}
            onPress={props.function2}
            //onPress={navigation.navigate('Home')}

        >
        <MaterialCommunityIcons name="home" color={props.color} size={30}/>
        </TouchableOpacity>
            : null}

        {/*isWeb && props.refresh ?
            <TouchableOpacity
                style={{
                    marginRight: '2%',
                }}
                onPress={props.function2}
            >
                <Ionicons name="refresh" size={30} color={props.color}/>
            </TouchableOpacity>

            : null*/}

    </SafeAreaView>
)