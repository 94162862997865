import {ActivityIndicator, Modal, Text, TouchableOpacity, View} from "react-native";
import {Ionicons} from "@expo/vector-icons";
import * as React from "react";

export default function Conferma(props) {

    return (
        <Modal
            transparent={true}
            animationType={'none'}
            visible={props.loading}>
            <View style={{
                flex: 1,
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'space-around',
                backgroundColor: '#00000040'
            }}>
                <View style={{
                    backgroundColor: '#FFFFFF',
                    height: 100,
                    width: 100,
                    borderRadius: 10,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-around'
                }}>
                    <Text>Sei sicuro?</Text>
                    <View style={{
                        flexDirection: 'row'
                    }}>
                        <TouchableOpacity style={{
                            margin: 10,
                            padding: 5,
                            borderRadius: 10,
                            backgroundColor: 'green'
                        }}
                                          onPress={props.funcSi}>
                            <Text>SI</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={{
                            margin: 10,
                            padding: 5,
                            borderRadius: 10,
                            backgroundColor: 'red'
                        }}
                                          onPress={props.funcNo}>
                            <Text>NO</Text>
                        </TouchableOpacity>
                    </View>

                </View>
            </View>
        </Modal>
    )
}