import {
    Alert,
    Image, ImageBackground,
    Platform, SafeAreaView, ScrollView,
    Text,
    View
} from "react-native";
import * as React from 'react';
import {AntDesign, Ionicons, MaterialCommunityIcons, MaterialIcons} from "@expo/vector-icons";
import {styles} from "../../global";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Constants from "expo-constants";

const isWeb = Platform.OS === 'web'

const iconColor = 'white'
const backgroundColor = 'black'
const size = 50

export default function Home({navigation}) {
    const [cf, setCf] = React.useState('')

    /*return (
        <View
            style={{
                flex: 1,
                ///backgroundColor: 'white',
                //alignItems: 'center',
                //justifyContent: 'center',
            }}>
            <ImageBackground source={require('./assets/s.jpg')}
                             resizeMode="cover"
                             style={{
                                 flex: 1,
                                 //justifyContent: "center",
                                 //alignItems: 'center',
                                 //aspectRatio:1/2,
                                 //width: wp('100%')
                                 //height:'80%',
                             }}>
                <FlatList data={data}
                    //ItemSeparatorComponent = {() => <View style={{ height: .5, width: "100%", backgroundColor: "#000" }}/>}
                    //keyExtractor={(item, index) => index.toString()}
                    //refreshing={refreshing}
                    //onRefresh={onRefresh}
                          renderItem={({item}) => (
                              <View
                                  style={{
                                      flex: 1,
                                      alignItems: 'flex-start',
                                      marginTop: '4%',
                                      marginLeft: '4%',
                                      //justifyContent: 'center'
                                  }}
                              >
                                  <Text
                                      style={{
                                          //flex: 1,
                                          //justifyContent: "center",
                                          //alignItems: 'center',
                                          //aspectRatio:1/2,
                                          //width: wp('100%')
                                          //height:'80%',
                                          fontSize: 34,
                                          transform: [{rotate: '-5deg'}],
                                          color: 'purple',
                                      }}>
                                      {item.title}
                                  </Text>
                              </View>
                          )}/>
            </ImageBackground>
        </View>
    );*/

    React.useEffect(async () => {
        /*const bootstrapAsync = async () => {
            const letto = await AsyncStorage.getItem('letto')
            if (letto === null){
                !isWeb ?
                    Alert.alert('BENVENUTO/A',
                        'L\'app è ancora in fase di sviluppo. \nLa grafica e molte funzionalità saranno aggiornate e rese disponibili a breve') :
                    alert('BENVENUTO/A, l\'app è ancora in fase di sviluppo. ' +
                        'La grafica e molte funzionalità saranno aggiornate e rese disponibili a breve')
                await AsyncStorage.setItem('letto', '1')
            }

        }
        bootstrapAsync()*/


        /*return () => {
            const bootstrapAsync = async () => {
                //await ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT_UP);
            }
            bootstrapAsync();
        };*/
      /*a     if (Notification.permission !== "granted") {
              Notification.requestPermission();
          }
          if (Notification.permission === "granted") {
              new Notification('ciaooooo')
          }*/

    /*    async function regWorker() {

            const dominio = await AsyncStorage.getItem('dominio')
            const email = await AsyncStorage.getItem('userToken')

            // (B1) YOUR PUBLIC KEY - CHANGE TO YOUR OWN!
            const publicKey = "BF-QEks7jY40RpuXV0glA7VYQbY7fvKTMIdMo_RrMjr3gtPW7KpolpamSz1a5tXNXPx66PYqRSC_OoU35BbSgyA";

            // (B2) REGISTER SERVICE WORKER
            await navigator.serviceWorker.register("./scripts/notification/sw.js").then(function (swRegistration) {
                //you can do something with the service wrker registration (swRegistration)
            });

            // (B3) SUBSCRIBE TO PUSH SERVER
            navigator.serviceWorker.ready.then(function (serviceWorker) {
                // subscribe and return the subscription
                serviceWorker.pushManager
                    .subscribe({
                        userVisibleOnly: true,
                        applicationServerKey: publicKey
                    })
                    .then(function (subscription) {
                        console.log(subscription)
                        let obj = JSON.parse(JSON.stringify(subscription))
                        console.log(obj)
                        fetch(dominio + 'notification/subscription.php', {
                            method: 'POST',
                            body: JSON.stringify({
                                email: email,
                                endpoint: obj.endpoint,
                                p256dh: obj.keys.p256dh,
                                auth: obj.keys.auth
                            })
                        }).then((response) => response.json())
                            .then((responseJson) => {
                                    console.log(responseJson)
                                }
                            ).catch((error) => {
                            console.error(error);
                        })

                    });
            })
        }

        if (Notification.permission === "default") {
            Notification.requestPermission().then(perm => {
                if (Notification.permission === "granted") {
                    regWorker().catch(err => console.error(err));
                } else {
                    alert("Attiva le notifiche");
                }
            });
        }

        // (A2) GRANTED
        else if (Notification.permission === "granted") {
            regWorker().catch(err => console.error(err));
        }

        // (A3) DENIED
        else {
            alert("Attiva le notifiche");
        }*/

    }, []);

    return (

        <SafeAreaView style={{
            flex: 1,
            //width: Dimensions.get('window').width,
            //height: Dimensions.get('window').height,
            backgroundColor: backgroundColor,
            paddingTop: Constants.statusBarHeight,
        }}>
            <ImageBackground source={require('../../assets/s.jpeg')}
                             resizeMode="stretch"
                             style={{
                                 flex: 1,
                                 //justifyContent: "center",
                                 //alignItems: 'center',
                                 //aspectRatio:1/2,
                                 //width: wp('100%')
                                 //height:'80%',
                             }}
                             imageStyle={{
                                 opacity: 0.2,
                             }}
            >

                <View style={{
                    alignItems: 'center', marginTop: '2%',
                    height: '20%'
                }}>
                    <Image source={require('../../assets/logon.jpg')}
                           style={{
                               width: isWeb ? 2502 / 100 * 10 : 2502 / 100 * 10,
                               height: isWeb ? 1023 / 100 * 10 : 1023 / 100 * 10,
                           }}/>
                </View>

                <Ionicons name="ios-notifications" size={24} color="white" style={{marginLeft: '87%'}}
                          onPress={() => {
                              !isWeb ?
                                  Alert.alert('PRESTO DISPONIBILE') :
                                  alert('PRESTO DISPONIBILE')
                          }}
                />

                <ScrollView>

                    <View style={{
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>

                        <View style={{
                            flexDirection: 'row',
                            //height: '80%',

                        }}>

                            <View>

                                <View style={styles.iconView}>
                                    <AntDesign name="clockcircle" size={size} color={iconColor} style={styles.icon}
                                               onPress={() => navigation.navigate('Orario')}/>
                                    <Text style={styles.iconText}>Orari</Text>
                                </View>

                                <View style={styles.iconView}>
                                    <MaterialCommunityIcons name="dance-ballroom" size={size} color={iconColor}
                                                            style={styles.icon}
                                                            onPress={() => navigation.navigate('Corsi')}/>
                                    <Text style={styles.iconText}>Corsi</Text>
                                </View>

                                <View style={styles.iconView}>
                                    <Ionicons name="ios-document-text" size={size} color={iconColor} style={styles.icon}
                                              onPress={() => navigation.navigate('Documenti')}
                                    />
                                    <Text style={styles.iconText}>Documenti</Text>
                                </View>

                                <View style={styles.iconView}>
                                    <Ionicons name="person" size={size} color={iconColor} style={styles.icon}
                                              onPress={() => navigation.navigate('Profilo')}/>
                                    <Text style={styles.iconText}>Profilo</Text>
                                </View>

                            </View>
                            <View>

                                <View style={styles.iconView}>
                                    <MaterialCommunityIcons name="calendar" size={size} color={iconColor}
                                                            style={styles.icon}
                                                            onPress={() => navigation.navigate('Eventi')}/>
                                    <Text style={styles.iconText}>Eventi</Text>
                                </View>

                                <View style={styles.iconView}>
                                    <MaterialCommunityIcons name="image" size={size} color={iconColor}
                                                            style={styles.icon}
                                                            onPress={() => navigation.navigate('Gallery')}

                                    />
                                    <Text style={styles.iconText}>Gallery</Text>
                                </View>

                                <View style={styles.iconView}>
                                    <MaterialIcons name="payment" size={size} color={iconColor} style={styles.icon}
                                                   onPress={() => navigation.navigate('Pagamenti')}/>
                                    <Text style={styles.iconText}>Pagamenti</Text>
                                </View>

                                <View style={styles.iconView}>
                                    <Ionicons name="information-circle" size={size} color={iconColor}
                                              style={styles.icon}
                                              onPress={() => navigation.navigate('Info')}/>
                                    <Text style={styles.iconText}>Info</Text>
                                </View>

                            </View>
                        </View>

                        <Image source={require('../../assets/scritta.jpg')}
                               style={{
                                   resizeMode: 'contain',
                                   width: 300,
                                   height: 100,
                                   opacity: 0.2
                               }}
                        />

                    </View>
                </ScrollView>
            </ImageBackground>
        </SafeAreaView>
    )
}
