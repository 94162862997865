import {
    Image,
    ImageBackground,
    Linking,
    SafeAreaView,
    ScrollView, StatusBar,
    Text,
    ToastAndroid,
    TouchableOpacity,
    View
} from "react-native";
import Constants from "expo-constants";
import {isWeb} from "../../global";
import {GoBack} from "../common/goBack";
import * as Clipboard from 'expo-clipboard';
import {Snackbar} from "react-native-paper";
import React from "react";

export default function Pay({navigation}) {

    const [visible, setVisible] = React.useState(false);

    return (
        <View style={{
            flex: 1,
            backgroundColor: 'white',
            paddingTop: Constants.statusBarHeight,
        }}>
            <GoBack function={() => navigation.goBack()} color='black'/>
            <ScrollView>
                <View style={{
                    alignItems: 'center',
                    margin: 3
                    //height: '20%'
                }}>
                    <Image source={require('../../assets/logo.jpg')}
                           style={{
                               width: isWeb ? 2502 / 100 * 10 : 2502 / 100 * 10,
                               height: isWeb ? 1023 / 100 * 10 : 1023 / 100 * 10,
                               marginBottom: 20
                           }}/>
                    <Text style={{fontSize: 22, textAlign: 'center', marginBottom: 10}}>Rinnova la tua quota sociale con
                        un semplice click</Text>

                    <View style={{alignItems: 'center', borderWidth: 3, borderColor: 'blue', borderRadius: 40, padding:5}}>
                        <View style={{
                            width: 150,
                            height: 100
                        }}>
                            <Image source={require('../../assets/banca.png')}
                                   style={{
                                       resizeMode: 'contain',
                                       width: '100%',
                                       height: '100%'
                                   }}/>
                        </View>

                        <Text style={{fontSize: 20, textAlign: 'center', marginBottom: 2}}>Bonifico su iban:</Text>

                        <Text style={{fontSize: 22, textAlign: 'center', marginBottom: 10, color: 'blue'}}
                              onPress={async () => {
                                  await Clipboard.setStringAsync('IT05D0326878930052466500030')
                                  setVisible(!visible)
                              }}
                        >IT05D0326878930052466500030</Text>

                        <Text style={{fontSize: 20, textAlign: 'center', marginBottom: 2}}>Intestato a:</Text>

                        <Text style={{fontSize: 22, textAlign: 'center', marginBottom: 10, color: 'blue'}}
                              onPress={async () => {
                                  await Clipboard.setStringAsync('ASD DANIEL\'S DANCE SCHOOL')
                                  setVisible(!visible)
                              }}
                        >ASD DANIEL'S DANCE SCHOOL</Text>

                        {/*<Text style={{fontSize: 20, textAlign: 'center', marginBottom: 2}}>Causale:</Text>

                        <Text style={{fontSize: 20, textAlign: 'center', marginBottom: 10, color: 'blue'}}
                              onPress={async () => {
                                  await Clipboard.setStringAsync('rinnovo quota sociale mese ... dell\'atleta')
                                  setVisible(!visible)
                              }}
                        >rinnovo quota sociale mese ... dell'atleta ...</Text>*/}

                        <Text style={{fontSize: 10, textAlign: 'center', marginBottom: 10,}}>
                            CLICCA SULLE SCRITTE IN BLU PER COPIARLE E POI INCOLLARLE {"\n"}NELL' APP DELLA TUA BANCA</Text>

                    </View>


                </View>

                <View style={{flexDirection: 'row', justifyContent: 'space-around', marginBottom: 20}}>

                    <View style={{alignItems: 'center'}}>
                        <View style={{
                            width: 150,
                            height: 80
                        }}>
                            <Image source={require('../../assets/paypal.png')}
                                   style={{
                                       resizeMode: 'contain',
                                       width: '100%',
                                       height: '100%'
                                   }}/>
                        </View>
                        <TouchableOpacity
                            onPress={() => Linking.openURL('https://www.paypal.com/qrcodes/managed/8ae89f3f-0c27-4312-92f4-bdf0f0fd14d1?utm_source=InPersonHome')}
                        >
                            <Image source={require('../../assets/pqr.png')}
                                   style={{
                                       width: 160,
                                       height: 200
                                   }}/>
                        </TouchableOpacity>
                    </View>

                    <View style={{alignItems: 'center'}}>
                        <View style={{
                            width: 150,
                            height: 80
                        }}>
                            <Image source={require('../../assets/satispay.png')}
                                   style={{
                                       resizeMode: 'contain',
                                       width: '100%',
                                       height: '100%'
                                   }}/>
                        </View>
                        <TouchableOpacity
                            onPress={() => Linking.openURL('https://www.satispay.com/download/qrcode/S6Y-SHP--89DFF743-0FD2-4539-AF50-43795A581EAD')}
                        >
                            <Image source={require('../../assets/sqr.png')}
                                   style={{
                                       width: 160,
                                       height: 200
                                   }}/>
                        </TouchableOpacity>
                    </View>
                </View>
            </ScrollView>

            <Snackbar
                visible={visible}
                onDismiss={() => setVisible(false)}
                duration={1000}
                wrapperStyle={{}}
                style={{                zIndex: 5
                }}
                action={{
                    label: 'ok',
                    onPress: () => {
                        setVisible(false)                    },
                }}>
                Testo copiato
            </Snackbar>

        </View>
    )
}