
let dominio = 'https://www.dds07.it/scripts/';
export async function Fetch(scelta, query) {

    let response = await fetch(dominio + 'fetch.php', {
        method: 'POST',
        body: JSON.stringify({
            scelta: scelta,
            query: query
        })
    })

    if (response.ok) {
        return await response.json()
    } else {
        alert("ERRPRE SERVER " + response.status);
    }
    /*
        .then((response) => response.json())
        .then((responseJson) => {
                return responseJson
            }
        ).catch((error) => {
        console.error(error);
    })*/
}

export async function SpecificFetch(path, body) {

    let response = await fetch(dominio + path, {
        method: 'POST',
        body: JSON.stringify(body)
    })

    if (response.ok) {
        return await response.json()
    } else {
        alert("ERRPRE SERVER " + response.status);
    }

/*
        .then((response) => response.json())
        .then((responseJson) => {
                return responseJson
            }
        ).catch((error) => {
        console.error(error);
    })*/
}