import {ActivityIndicator, Modal, Text, TouchableOpacity, View} from "react-native";
import {Ionicons} from "@expo/vector-icons";
import * as React from "react";

export default function Update(props){

    return(
        <Modal
            transparent={true}
            animationType={'none'}
            visible={props.loading}>
            <View style={{
                flex: 1,
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'space-around',
                backgroundColor: '#00000040'
            }}>
                <View style={{
                    backgroundColor: '#FFFFFF',
                    height: 100,
                    width: 100,
                    borderRadius: 10,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-around'
                }}>
                    <ActivityIndicator
                        //animating={loading}
                    />
                    {props.other}

                </View>
            </View>
        </Modal>
    )
}