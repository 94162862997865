import {Text, TouchableOpacity} from "react-native";
import {Ionicons} from "@expo/vector-icons";
import * as React from "react";

export default function Evento(props){

    return(
        <TouchableOpacity
            style={{
                borderRadius: 15,
                backgroundColor: 'green',
                borderWidth: 1,
                margin: 15,
                padding: 10
            }}
            onPress={props.function}
        >
            <Text>CARICA EVENTO</Text>
        </TouchableOpacity>
    )
}