import {Alert, SafeAreaView, StyleSheet, Text, TextInput, TouchableOpacity, View} from "react-native";
import React from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {isWeb} from "../../global";
import {Ionicons} from "@expo/vector-icons";
import Constants from "expo-constants";
export default function RecoverPass({navigation, route}) {
    const {email} = route.params
    const [oldPass, setOldPass] = React.useState(null);
    const [newPass, setNewPass] = React.useState(null);
    const [newPass2, setNewPass2] = React.useState(null);

    function Controllo() {
        if (!oldPass || oldPass === '' || !newPass || newPass === '' || !newPass2 || newPass2 === '' ||
            oldPass.length < 8) {
            return (
                !isWeb ?
                    Alert.alert('COMPLETARE TUTTI I CAMPI') :
                    alert('COMPLETARE TUTTI I CAMPI')
            )
        } else {
            if (newPass === newPass2) {
                UpdatePass()
            } else {
                !isWeb ?
                    Alert.alert('LE PASSWORD NON CORRISPONDONO') :
                    alert('LE PASSWORD NON CORRISPONDONO')
            }
        }
    }

    async function UpdatePass(){
        const dominio = await AsyncStorage.getItem('dominio')
        fetch(dominio + 'authentication/updatePassword.php', {
            method: 'POST',
            body: JSON.stringify({
                email: email,
                oldPass: oldPass,
                newPass2: newPass2
            })
        }).then((response) => response.json())
            .then((responseJson) => {
                !isWeb ? Alert.alert(responseJson) :
                    alert(responseJson)
            }).catch((error) => {
            console.error(error);
        })//.finally(() => setLoading(false));
    }

    return (
        <View style={{
            flex: 1,
            backgroundColor: 'white',
            paddingTop: Constants.statusBarHeight,

        }}>
        <Ionicons name="arrow-back" size={26} color="black"
                  style={{
                      marginLeft: '2%',
                      marginTop: '2%'
                  }}
                  onPress={()=> {
                      navigation.goBack()
                      //ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT_UP)
                  }}/>
        <View style={{
           flex: 1,
            //backgroundColor: 'white',
            alignItems: 'center',
            justifyContent: 'center'
        }}>


            <TextInput
                placeholder="INSERISCI VECCHIA PASSWORD"
                onChangeText={data => setOldPass(data)}
                underlineColorAndroid='transparent'
                style={styles.TextInputStyleClass}
                secureTextEntry={true}
            />
            <Text style={{
                color: 'red',
                marginBottom: 5
            }}>{oldPass === '' ? 'campo obbligatorio' : '' ||
            oldPass ? oldPass.length < 8 ? 'la password deve essere lunga almeno 8 caratteri' : '' : ''
            }</Text>


            <TextInput
                placeholder="INSERISCI NUOVA PASSWORD"
                onChangeText={data => setNewPass(data)}
                underlineColorAndroid='transparent'
                style={styles.TextInputStyleClass}
                secureTextEntry={true}
            />
            <Text style={{
                color: 'red',
                marginBottom: 5
            }}>{newPass === '' ? 'campo obbligatorio' : '' ||
            newPass ? newPass.length < 8 ? 'la password deve essere lunga almeno 8 caratteri' : '' : ''
            }</Text>
            <TextInput
                placeholder="RIPETI NUOVA PASSWORD"
                onChangeText={data => setNewPass2(data)}
                underlineColorAndroid='transparent'
                style={styles.TextInputStyleClass}
                secureTextEntry={true}
            />
            <Text style={{
                color: 'red',
                marginBottom: 5
            }}>{
                newPass2 === '' ? 'campo obbligatorio' : '' ||
                newPass2 !== newPass ? 'le password non corrispondono' : ''}</Text>

            <TouchableOpacity style={{
                marginTop: 30,
                borderWidth: 1,
                borderRadius: 10,
                alignItems: 'center',
                justifyContent: 'center',
                width: '50%',
                textAlign: "center",
                fontSize: 30,
                fontWeight: "bold",
                marginBottom: 40
            }} onPress={() => Controllo()}>

                <Text style={{
                    fontSize: 20,
                    fontWeight: "bold",
                    textAlign: 'center'
                }}>AGGIORNA PASSWORD</Text>

            </TouchableOpacity>

        </View>
        </View>
    )
}

const styles = StyleSheet.create({

    MainContainer: {

        justifyContent: 'center',
        //alignItems: 'center',
        flex: 1,
        margin: 10
    },

    TextInputStyleClass: {
        textAlign: 'center',
        marginBottom: 5,
        height: 40,
        width: '60%',
        borderWidth: 1,
        borderColor: 'black',
        borderRadius: 10,
    },


});